import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export interface UseConfirmOptions {
  title?: string;
  text?: string;
}

export default function useConfirm(options?: UseConfirmOptions) {
  const [open, setOpen] = useState(false);
  const func = useRef<(() => any) | undefined>();

  const confirm = (successAction: () => any) => {
    func.current = successAction;
    setOpen(true);
  };

  const handleConfirm = () => {
    if (!func.current) return;
    func.current();
    handleClose();
  };

  const handleClose = () => {
    func.current = undefined;
    setOpen(false);
  };

  const ConfirmDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={600}>
            {options?.title || "Confirm"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: { xs: 300, md: 400 } }}>
          <DialogContentText>
            {options?.text || "Are you sure you want to proceed?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3, pb: 2, pt: 0 }}>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return { confirm, ConfirmDialog };
}
