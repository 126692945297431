import { Button, Chip, Paper, Stack, TableCell } from "@mui/material";
import ManagementTemplate from "../../templates/ManagementTemplate";
import DataTable, { QueryParameters } from "../../organisms/DataTable";
import { useState } from "react";
import { PaginatedListUser, User } from "../../../services/swagger";
import { UserService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import useConfirm from "../../../hooks/useConfirm";
import { capitalizeFirstLetter } from "../../../helpers/string";
import CreateUserDialog from "../../organisms/CreateUserDialog";
import { useNavigate } from "react-router";

export default function UsersPage() {
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [createDialog, setCreateDialog] = useState(false);
  const [users, setUsers] = useState<PaginatedListUser>({
    count: 0,
    docs: [],
  });

  const loadUsers = async (query: QueryParameters) => {
    const res = await UserService.getUserList({
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (res?.data?.data) setUsers(res?.data?.data);
  };

  const handleDelete = async (user: User) => {
    await UserService.deleteUser({ id: user._id?.toString() || "" });
    refresh();
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadUsers(query);
  };

  const { confirm: confirmDelete, ConfirmDialog } = useConfirm();

  return (
    <ManagementTemplate paper>
      <ConfirmDialog />
      <CreateUserDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
      />
      <DataTable
        name="Users"
        headers={[
          { field: "name", name: "Name", type: "string" },
          { field: "email", name: "Email", type: "string" },
          { field: "company", name: "Company", type: "string" },
          { field: "phone", name: "Phone", type: "string" },
          { field: "username", name: "Username", type: "string" },
          {
            field: "role",
            name: "Role",
            type: "other",
            renderHeaderCell: () => (
              <TableCell width={100} align="center">
                Role
              </TableCell>
            ),
            renderRowCell: (row: User) => (
              <TableCell key={row._id} align="center">
                <Chip label={capitalizeFirstLetter(row.role)} />
              </TableCell>
            ),
          },
          {
            field: "delete",
            name: "",
            type: "other",
            renderHeaderCell: () => (
              <TableCell align="center" width={150}>
                Action
              </TableCell>
            ),
            renderRowCell: (row: User) => (
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => navigate(`/m/user/${row._id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => confirmDelete(() => handleDelete(row))}
                  >
                    Delete
                  </Button>
                </Stack>
              </TableCell>
            ),
          },
        ]}
        onChange={handleDataTableChange}
        content={users}
        pagination
        queryFunction={UserService.getUserListQuery}
        button={{
          text: "New User",
          onClick: () => setCreateDialog(true),
        }}
      />
    </ManagementTemplate>
  );
}
