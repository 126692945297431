import { Stack } from "@mui/material";
import { useState } from "react";
import FormInput from "../../molecules/FormInput";
import { FTPSettings } from "../../../services/swagger";
import FormTemplate from "../../templates/FormTemplate";

interface FtpSetupProps {
  ftpSettings?: FTPSettings;
  onSubmit: (ftpConfig: FTPSettings) => void;
  onCancel: () => void;
}

export default function FtpSetup(props: FtpSetupProps) {
  const [username, setUsername] = useState(props.ftpSettings?.username || "");
  const [password, setPassword] = useState(props.ftpSettings?.password || "");
  const [host, setHost] = useState(props.ftpSettings?.host || "");
  const [port, setPort] = useState(props.ftpSettings?.port || 21);
  const [secure, setSecure] = useState(props.ftpSettings?.secure || false);
  const [uploadPath, setUploadPath] = useState(
    props.ftpSettings?.uploadPath || "/"
  );

  const handleSubmit = () => {
    props.onSubmit({
      enabled: true,
      password,
      port,
      secure,
      host,
      username,
      uploadPath,
    });
  };

  return (
    <FormTemplate
      title="FTP Setup"
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      cancelButtonText="Back"
      submitButtonText="Next"
      disableHeaderCloseButton
    >
      <Stack spacing={1}>
        <FormInput
          required
          label="Host"
          placeholder="localhost"
          field="host"
          type="string"
          value={host}
          onChange={(value) => setHost(value as string)}
        />
        <FormInput
          required
          label="Username"
          field="username"
          type="string"
          value={username}
          onChange={(value) => setUsername(value as string)}
        />

        <FormInput
          required
          label="Password"
          field="password"
          inputType="password"
          type="string"
          value={password}
          onChange={(value) => setPassword(value as string)}
        />
        <FormInput
          required
          label="Port"
          field="port"
          type="number"
          value={port}
          onChange={(value) => setPort(value as number)}
        />
        <FormInput
          label="Secure"
          field="secure"
          type="switch"
          value={secure}
          onChange={(value) => setSecure(value as boolean)}
        />
        <FormInput
          required
          label="Upload Path"
          field="uploadPath"
          type="string"
          value={uploadPath}
          onChange={(value) => setUploadPath(value as string)}
        />
      </Stack>
    </FormTemplate>
  );
}
