import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Button, Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";

export default function SmtpSettingsPage() {
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [enabled, setEnabled] = useState(false);
  const [server, setServer] = useState("");
  const [port, setPort] = useState(0);
  const [secure, setSecure] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [from, setFrom] = useState("");

  const [testEmail, setTestEmail] = useState("");

  const handleTestEmail = async () => {
    await SettingsService.createSmtpTest({ testEmail });
  };

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
        smtp: {
          enabled,
          server,
          from,
          username,
          password,
          port,
          secure,
        },
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setEnabled(context.settings.smtp.enabled);
    setServer(context.settings.smtp.server);
    setFrom(context.settings.smtp.from);
    setUsername(context.settings.smtp.username);
    setPassword(context.settings.smtp.password);
    setSecure(context.settings.smtp.secure);
    setPort(context.settings.smtp.port);
  }, [context.settings]);

  return (
    <FormTemplate
      title="SMTP"
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText="Save"
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="enabled"
          label="Enabled"
          type="boolean"
          value={enabled}
          onChange={(value) => setEnabled(value as boolean)}
        />
        <FormInput
          field="server"
          label="Server"
          placeholder="smtp.gmail.com"
          type="string"
          value={server}
          onChange={(value) => setServer(value.toString())}
        />
        <FormInput
          field="port"
          label="Port"
          type="number"
          value={Number.isInteger(port) ? port : 0}
          onChange={(value) => setPort(parseInt(value.toString()))}
        />
        <FormInput
          field="username"
          label="Username"
          type="string"
          value={username}
          onChange={(value) => setUsername(value.toString())}
        />
        <FormInput
          field="password"
          label="Password"
          type="string"
          value={password}
          onChange={(value) => setPassword(value.toString())}
        />
        <FormInput
          field="from"
          label="From Email"
          type="string"
          value={from}
          onChange={(value) => setFrom(value.toString())}
        />
        <FormInput
          field="secure"
          label="Secure Connection?"
          type="boolean"
          value={secure}
          required
          onChange={(value) => setSecure(value as boolean)}
        />
        <FormInput
          field="testEmail"
          label="Test Email"
          placeholder="john.doe@email.com"
          type="string"
          value={testEmail}
          onChange={(value) => setTestEmail(value as string)}
        />

        <Button
          sx={{ minWidth: 150, width: "fit-content" }}
          onClick={handleTestEmail}
          variant="contained"
        >
          Test
        </Button>
      </Stack>
    </FormTemplate>
  );
}
