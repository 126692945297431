import { Box, Typography } from "@mui/material";

interface HomeCardModuleProps {
  title: string;
  children: string;
}

export default function HomeCardModule(props: HomeCardModuleProps) {
  return (
    <Box
      sx={{
        p: 4,
        bgcolor: "background.default",
        height: { xs: "fit-content", md: 240 },
        borderRadius: 5,
        boxShadow: "0px 4px 20px 0px #0000001A",
      }}
    >
      <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
        {props.title}
      </Typography>
      <Typography fontWeight="light" align="justify">
        {props.children}
      </Typography>
    </Box>
  );
}
