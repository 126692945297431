import { ButtonBase, Typography } from "@mui/material";
import { useMatch, useNavigate } from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";

interface SideBarButtonBaseProps {
  icon?: any;
  value: string;
  matched: boolean;
  onClick: () => any;
}

export default function SideBarButtonBase(props: SideBarButtonBaseProps) {
  const { context } = useGlobalContext();

  return (
    <ButtonBase
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: (theme) => theme.shape.borderRadius,
        transitionDuration: "200ms",
        p: context.sidebar ? 1 : 1.5,
        py: 1.5,
        fontWeight: "light",
        color: props.matched
          ? (theme) => theme.palette.primary.main
          : (theme) => theme.palette.text.secondary,
        background: props.matched
          ? (theme) => theme.palette.background.paper
          : "none",
        ":hover": {
          color: (theme) =>
            props.matched
              ? theme.palette.primary.main
              : theme.palette.text.primary,
          background: (theme) => theme.palette.background.paper,
        },
        ":hover > p": {
          transitionDuration: "600ms",
          ml: 1,
        },
      }}
      onClick={props.onClick}
      focusRipple
    >
      {props.icon}
      {context.sidebar && (
        <Typography fontWeight={500} pl={props.icon ? 1 : 0}>
          {props.value}
        </Typography>
      )}
    </ButtonBase>
  );
}
