import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";

export default function OpenAISettingsPage() {
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [enabled, setEnabled] = useState(false);
  const [assistantId, setAssistantId] = useState("");
  const [key, setKey] = useState("");
  const [organizationId, setOrganizationId] = useState("");

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
        openai: {
          enabled,
          assistantId,
          apiKey: key,
          organizationId,
        },
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setEnabled(context.settings.openai.enabled);
    setAssistantId(context.settings.openai.assistantId);
    setKey(context.settings.openai.apiKey);
    setOrganizationId(context.settings.openai.organizationId);
  }, [context.settings]);

  return (
    <FormTemplate
      title="OpenAI"
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText="Save"
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="enabled"
          label="Enabled"
          type="boolean"
          value={enabled}
          onChange={(value) => setEnabled(value as boolean)}
        />
        <FormInput
          field="assistantId"
          label="Assistant ID"
          type="string"
          value={assistantId}
          onChange={(value) => setAssistantId(value.toString())}
        />
        <FormInput
          field="apiKey"
          label="API Key"
          type="string"
          value={key}
          onChange={(value) => setKey(value.toString())}
        />
        <FormInput
          field="organizationId"
          label="Organization ID"
          type="string"
          value={organizationId}
          onChange={(value) => setOrganizationId(value.toString())}
        />
      </Stack>
    </FormTemplate>
  );
}
