import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import HomePage from "./components/pages/Home";
import { Box, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useGlobalContext } from "./contexts/Global";
import LoginPage from "./components/pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPage from "./components/pages/Register";
import { AuthService, SettingsService } from "./services/api";
import MyTicketsPage from "./components/pages/MyTickets";
import DashboardPage from "./components/pages/Dashboard";
import SecurityPage from "./components/pages/Security";
import SettingsPage from "./components/pages/Settings";
import UsersPage from "./components/pages/Users";
import EditUserPage from "./components/pages/Users/EditUser";
import StoragePage from "./components/pages/Storage";
import TicketsPage from "./components/pages/Tickets";
import CreateMyTicketPage from "./components/pages/Tickets/CreateMyTicket";
import CreateManagementTicketPage from "./components/pages/Tickets/CreateManagementTicket";
import ManageTicketPage from "./components/pages/Tickets/ManageTicket";
import MainTemplate from "./components/templates/MainTemplate";
import ManagementTemplate from "./components/templates/ManagementTemplate";
import { startServiceWorker, stopServiceWorker } from "./workers/main";
import EditDepartmentPage from "./components/pages/Departments/EditDepartment";
import DepartmentsPage from "./components/pages/Departments";
import SetupPage from "./components/pages/Setup";
import ForgotPasswordPage from "./components/pages/ForgotPassword";
import { lightTheme } from "./themes/akcaba";
import AccountInfoPage from "./components/pages/AccountInfo";
import useBreakpoint from "./hooks/useBreakpoint";
import LogsPage from "./components/pages/Logs";

function IndexRoute() {
  const navigate = useNavigate();
  const checkSetup = async () => {
    const res = await SettingsService.getSetup();
    if (!res) return navigate("/home");
    const setup = res.data.data;
    if (!setup) navigate("/setup");
    else navigate("/home");
  };

  useEffect(() => {
    checkSetup();
  }, []);
  return null;
}

function IndexManagementRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/m/dashboard");
  }, []);
  return null;
}

export default function App() {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  // useSocket(true);

  useEffect(() => {
    if (!localStorage.getItem("authToken"))
      setContext({ ...context, user: undefined });
    AuthService.getSession().then((res) => {
      if (res?.data?.success) {
        setContext({ ...context, user: res.data.data });
        startServiceWorker();
      } else {
        stopServiceWorker();
      }
    });
  }, [localStorage.getItem("authToken")]);

  return (
    <Box height={1} width={1}>
      <ToastContainer
        position={breakpoint.md ? "top-center" : "bottom-center"}
        style={{ zIndex: 999999999 }}
        bodyStyle={{ whiteSpace: "pre-line" }}
      />
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <Routes>
            <Route index element={<IndexRoute />} />
            <Route path="/setup" element={<SetupPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/my-tickets">
              <Route index element={<MyTicketsPage />} />
              <Route path="create" element={<CreateMyTicketPage />} />
              <Route
                path=":_id"
                element={<ManageTicketPage template={<MainTemplate />} />}
              />
            </Route>
            <Route path="/account">
              <Route index element={<ForgotPasswordPage />} />
              <Route path="info" element={<AccountInfoPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/m">
              <Route index element={<IndexManagementRoute />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="storage/*" element={<StoragePage />} />
              <Route path="ticket">
                <Route index element={<TicketsPage />} />
                <Route path="create" element={<CreateManagementTicketPage />} />
                <Route
                  path=":_id"
                  element={
                    <ManageTicketPage template={<ManagementTemplate />} />
                  }
                />
              </Route>
              <Route path="department">
                <Route index element={<DepartmentsPage />} />
                <Route path=":_id" element={<EditDepartmentPage />} />
              </Route>
              <Route path="user">
                <Route index element={<UsersPage />} />
                <Route path=":_id" element={<EditUserPage />} />
              </Route>
              <Route path="logs" element={<LogsPage />} />
              <Route path="settings/*" element={<SettingsPage />} />
              <Route path="security" element={<SecurityPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
}
