import { Alert, Box, Button, Divider, Stack, Typography } from "@mui/material";
import { DashboardData, Task } from "../../../services/swagger";
import { useState } from "react";
import TaskDialog from "../TaskDialog";

export interface DashboardTasksProps {
  data: DashboardData;
}

export interface DashboardTaskProps {
  task: Task;
}

function DashboardTask(props: DashboardTaskProps) {
  const { task } = props;
  const [open, setOpen] = useState(false);
  let severity: "info" | "error" | "success" | "warning" = "info";
  if (task.cancelDate) severity = "error";
  else if (task.endDate) severity = "success";
  else if (task.startDate) severity = "warning";
  return (
    <>
      <TaskDialog open={open} task={task} onClose={() => setOpen(false)} />
      <Alert
        severity={severity}
        action={
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setOpen(true)}
            sx={{ zIndex: 101 }}
          >
            Open
          </Button>
        }
      >
        {task.title}
      </Alert>
    </>
  );
}

export default function DashboardTasks(props: DashboardTasksProps) {
  const { data } = props;

  return (
    <Stack spacing={2} mb={2} sx={{ p: { xs: 2, md: 0 } }}>
      <Box>
        <Divider />
      </Box>
      {data.activeTasks.length ? (
        data.activeTasks.map((e) => {
          return <DashboardTask key={e._id} task={e} />;
        })
      ) : (
        <Alert severity="info">There are no active tasks</Alert>
      )}
    </Stack>
  );
}
