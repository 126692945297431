import { Divider, Paper, Tab, Tabs } from "@mui/material";
import TabPanel from "../../atoms/TabPanel";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { useMatch, useNavigate } from "react-router";
import OpenAISettingsPage from "./OpenAISettings";
import { useEffect } from "react";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";
import SapSettingsPage from "./SapSettings";
import SmtpSettingsPage from "./SmtpSettings";
import MessagesSettingsPage from "./MessageSettings";
import ForwardEmailSettingsPage from "./ForwardEmailSettings";
import FtpSettingsPage from "./FtpSettings";

const defaultSection = "messages";

export default function SettingsPage() {
  const { context, setContext } = useGlobalContext();
  const match = useMatch("/m/settings/:section");
  const navigate = useNavigate();
  const section = match?.params.section;

  const loadSettings = async () => {
    const res = await SettingsService.getSettings();
    if (!res?.data?.data) return;
    const settings = res.data.data;
    setContext({ ...context, settings });
  };

  useEffect(() => {
    loadSettings();
  }, []);

  useRefresh(loadSettings);

  useEffect(() => {
    if (!match?.params.section) navigate(`/m/settings/${defaultSection}`);
  }, [match?.params.section]);

  const handleChangeSection = (e: any, value: string) => {
    navigate(`/m/settings/${value}`);
  };

  return (
    <ManagementTemplate>
      <Paper sx={{ bgcolor: "background.default" }}>
        <Tabs value={section} onChange={handleChangeSection}>
          <Tab label="Messages" value="messages" />
          <Tab label="Forwards" value="forwards" />
          <Tab label="SMTP" value="smtp" />
          <Tab label="OpenAI" value="openai" />
          <Tab label="FTP" value="ftp" />
          <Tab label="SAP" value="sap" />
        </Tabs>
        <Divider />
        <TabPanel value={section} index="messages">
          <MessagesSettingsPage />
        </TabPanel>
        <TabPanel value={section} index="forwards">
          <ForwardEmailSettingsPage />
        </TabPanel>
        <TabPanel value={section} index="openai">
          <OpenAISettingsPage />
        </TabPanel>
        <TabPanel value={section} index="ftp">
          <FtpSettingsPage />
        </TabPanel>
        <TabPanel value={section} index="sap">
          <SapSettingsPage />
        </TabPanel>
        <TabPanel value={section} index="smtp">
          <SmtpSettingsPage />
        </TabPanel>
      </Paper>
    </ManagementTemplate>
  );
}
