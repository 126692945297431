import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  IconProps,
} from "@mui/material";
import { MouseEventHandler } from "react";
import Add from "@mui/icons-material/Add";

interface AddButtonProps {
  boxProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
  avatarProps?: AvatarProps;
  iconProps?: IconProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function AddButton(props: AddButtonProps) {
  return (
    <Box {...props.boxProps}>
      <IconButton
        size="small"
        onClick={props.onClick}
        sx={{ p: 0 }}
        {...props.iconButtonProps}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            height: 32,
            width: 32,
          }}
          {...props.avatarProps}
        >
          <Add
            sx={{
              ...props.iconProps?.sx,
              color: (theme) => theme.palette.error.contrastText,
            }}
          />
        </Avatar>
      </IconButton>
    </Box>
  );
}
