import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { TaskService } from "../../../services/api";
import FormInput from "../../molecules/FormInput";
import useRefresh from "../../../hooks/useRefresh";
import FormTemplate from "../../templates/FormTemplate";

interface CreateTaskDialogProps {
  open: boolean;
  ticketId?: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function CreateTaskDialog(props: CreateTaskDialogProps) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const refresh = useRefresh();

  useEffect(() => {
    setTitle("");
    setDescription("");
  }, []);

  const handleSubmit = async () => {
    if (!props.ticketId) return;
    const res = await TaskService.createTask({
      ticketId: props.ticketId,
      createTaskBody: {
        title,
        description,
      },
    });

    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title="Create Task"
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2}>
          <FormInput
            label="Title"
            value={title}
            placeholder="ex. Rearrange Warehouse"
            required
            field="title"
            type="string"
            onChange={(v) => setTitle(v as string)}
          />
          <FormInput
            label="Description"
            field="description"
            type="string"
            value={description}
            placeholder="ex. Rearrange the boxes by color order"
            required
            multiline
            rows={4}
            onChange={(v) => setDescription(v as string)}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
