import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { FormEvent, useState } from "react";
import UploadDialog from "../UploadDialog";
import AttachmentList from "../../molecules/AttachmentList";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useConfirm from "../../../hooks/useConfirm";
import useRefresh from "../../../hooks/useRefresh";
import { TicketService } from "../../../services/api";
import { useParams } from "react-router";
import Authorized from "../../atoms/Authorized";

interface CreateTicketResponseProps {
  ticketId: string;
}

export default function CreateTicketResponse(props: CreateTicketResponseProps) {
  const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const { confirm, ConfirmDialog } = useConfirm();
  const refresh = useRefresh();

  const handleChangeFiles = (newFiles: File[]) => {
    setFiles(newFiles);
    setOpen(false);
  };

  const handleRespond = async () => {
    if (!_id) return;
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await TicketService.createTicketResponse(
      {
        ticketId: _id,
        content: response,
      },
      { data: formData }
    );
    if (res?.data?.success) {
      refresh();
      setResponse("");
      setFiles([]);
    }
  };

  const handleClickRespond = (e: FormEvent) => {
    e.preventDefault();
    confirm(handleRespond);
  };

  return (
    <Paper
      sx={{ p: 2, bgcolor: "background.default" }}
      component="form"
      onSubmit={handleClickRespond}
    >
      <ConfirmDialog />
      <UploadDialog
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleChangeFiles}
      />
      <Stack spacing={2}>
        <Authorized role="user">
          <FormInput
            field="response"
            label="Response"
            onChange={(v) => setResponse(v as string)}
            type="string"
            value={response}
            required
            multiline
            minRows={4}
            placeholder="New Response"
          />
          <Stack spacing={1}>
            <Typography fontWeight={600}>Attachments</Typography>
            <Divider />
            <Box>
              {files.length === 0 ? (
                <Typography align="center" mt={1} mb={1}>
                  No Files Selected
                </Typography>
              ) : (
                <AttachmentList
                  ticketId={props.ticketId}
                  upload
                  files={files}
                />
              )}
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={files.length > 0 ? 6 : 12}>
                  <Button
                    onClick={() => setOpen(true)}
                    variant="contained"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </Button>
                </Grid>
                {files.length > 0 && (
                  <Grid item xs={6}>
                    <Button
                      color="error"
                      onClick={() => setFiles([])}
                      variant="contained"
                      fullWidth
                    >
                      Clear
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
          <Button variant="contained" color="success" type="submit">
            Respond
          </Button>
        </Authorized>
      </Stack>
    </Paper>
  );
}
