import { Avatar, ButtonBase, ButtonBaseProps } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function MenuButton(props: ButtonBaseProps) {
  return (
    <ButtonBase {...props}>
      <Avatar
        variant="rounded"
        sx={{
          height: 40,
          width: 40,
          bgcolor: (theme) => theme.palette.primary.light,
        }}
      >
        <MenuIcon
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
          }}
        />
      </Avatar>
    </ButtonBase>
  );
}
