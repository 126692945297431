import { useEffect, useRef } from "react";
import { useGlobalContext } from "../contexts/Global";
import { useSocket } from "./useSocket";
import { getBrowserId } from "../helpers/browser";

export default function useRefresh(
  action?: () => any,
  section?: string,
  ...paths: string[]
) {
  const initialized = useRef<boolean>(false);
  const { context, setContext } = useGlobalContext();
  // const socket = useSocket();

  const refresh = () => {
    setContext({ ...context, refresh: !context.refresh });
  };

  const handleRefresh = () => {
    if (!action) return;
    if (!initialized.current) {
      initialized.current = true;
      return;
    }
    action();
  };

  useEffect(handleRefresh, [context.refresh]);

  // function socketRefresh(
  //   browserId?: string,
  //   sectionIn?: string,
  //   ...pathsIn: string[]
  // ) {
  //   if (browserId && getBrowserId() === browserId) return;
  //   if (sectionIn && section?.toLowerCase() !== sectionIn.toLowerCase()) return;
  //   if (pathsIn) {
  //     for (let i = 0; i < paths.length; i++) {
  //       if (paths[i] !== pathsIn[i]) return;
  //     }
  //   }
  //   action?.();
  // }

  // useEffect(() => {
  //   if (!socket) return;
  //   socket?.on("refresh", socketRefresh);
  //   return () => {
  //     socket.off("refresh", socketRefresh);
  //   };
  // }, [socket]);

  return refresh;
}
