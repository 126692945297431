import { BarChart } from "@mui/x-charts";
import { DashboardData } from "../../../services/swagger";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";

interface DashboardStatusOverviewProps {
  data: DashboardData;
}

export default function DashboardStatusOverview(
  props: DashboardStatusOverviewProps
) {
  const { data } = props;
  const statusOverview = data.statusOverview;
  const theme = useTheme();

  const empty = useMemo(() => {
    return !statusOverview.some((item) => item.count > 0);
  }, [statusOverview]);

  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pb={1}
        pt={4}
        pl={4}
      >
        Tickets Status
      </Typography>
      <Box width={1} mb={2} pl={empty ? undefined : 4} position="relative">
        {empty ? (
          <Box>
            <BarChart
              series={[{ data: [] }]}
              yAxis={[{ tickMinStep: 1, disableTicks: true }]}
              xAxis={[
                {
                  data: [],
                  scaleType: "band",
                  disableTicks: true,
                },
              ]}
              borderRadius={4}
              colors={[theme.palette.primary.main]}
              height={300}
            />
          </Box>
        ) : (
          <>
            <Box position="absolute" top={67} bgcolor="red">
              {statusOverview.map((status, i) => {
                return (
                  <Box
                    position="absolute"
                    top={i * 50}
                    key={status.label}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      width={90}
                      textOverflow="ellipsis"
                      align="right"
                      fontSize={10}
                      color="text.secondary"
                      noWrap
                    >
                      {status.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box pl={6}>
              <BarChart
                layout="horizontal"
                series={[
                  {
                    data: statusOverview.map((status) => status.count),
                  },
                ]}
                barLabel="value"
                yAxis={[
                  {
                    data: statusOverview.map((status) => status.label),
                    position: "right",
                    scaleType: "band",
                    tickLabelStyle: {
                      opacity: 0,
                    },
                    colorMap: {
                      type: "ordinal",
                      colors: [theme.palette.primary.main],
                    },
                    disableTicks: true,
                  },
                ]}
                borderRadius={8}
                xAxis={[{ tickMinStep: 1, disableTicks: true }]}
                height={300}
              />
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
}
