import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Filter, QueryFilterTemplate } from "../../services/swagger";
import FilterInput, { allowedOperators } from "../molecules/FilterInput";
import AddIcon from "@mui/icons-material/Add";

interface FilterBoxProps {
  open?: boolean;
  filters?: QueryFilterTemplate[];
  onChange?: (filters: Filter[]) => void;
}

interface FilterWithTemplate {
  filter: Filter;
  template: QueryFilterTemplate;
}

export default function FilterBox(props: FilterBoxProps) {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [filtersApplied, setFiltersApplied] = useState<FilterWithTemplate[]>(
    []
  );
  const defaultLoaded = useRef<boolean>(false);

  const handleAddFilter = (filter: { name: string; value: string }) => {
    const newFilter = props.filters?.find((f) => f.field === filter.value);
    if (!newFilter) return;
    setFiltersApplied([
      ...filtersApplied,
      {
        template: newFilter,
        filter: {
          field: newFilter.field,
          operator: allowedOperators[newFilter.valueType][0],
          type: newFilter.valueType,
          value: undefined,
        },
      },
    ]);
    setAnchorEl(null);
  };

  const handleApplyFilters = (e?: FormEvent<HTMLElement>) => {
    e?.preventDefault?.();
    props.onChange?.(
      filtersApplied.map((filterWithTemplate) => filterWithTemplate.filter)
    );
  };
  const handleClearFilters = () => {
    setFiltersApplied([]);
  };

  useEffect(() => {
    if (!Array.isArray(props.filters)) return;
    const defaultFilters: FilterWithTemplate[] = [];
    for (const filter of props.filters) {
      if (!Array.isArray(filter.default)) continue;
      filter.default.forEach((defaultFilter) => {
        defaultFilters.push({
          template: filter,
          filter: {
            field: filter.field,
            operator: defaultFilter.operator,
            value: defaultFilter.value,
            type: filter.valueType,
          },
        });
      });
    }
    setFiltersApplied(defaultFilters);
    defaultLoaded.current = true;
  }, [props.filters]);

  useEffect(() => {
    if (!defaultLoaded.current) return;
    handleApplyFilters();
    defaultLoaded.current = false;
  }, [filtersApplied]);

  return (
    <Collapse in={props.open} unmountOnExit>
      <Box
        component="form"
        sx={{ p: 2, bgcolor: (theme) => theme.palette.background.default }}
        onSubmit={handleApplyFilters}
      >
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {[
            ...(props.filters?.map((filter) => {
              return {
                name: filter.name,
                value: filter.field,
              };
            }) || []),
          ].map((filter) => {
            return (
              <MenuItem
                key={filter.value}
                sx={{ minWidth: 200 }}
                onClick={() => handleAddFilter(filter)}
              >
                {filter.name}
              </MenuItem>
            );
          })}
        </Menu>
        <Grid container spacing={2}>
          {filtersApplied.map((filter, i) => {
            return (
              <Grid item key={i} xs={12} md={6} lg={4} xl={3}>
                <FilterInput
                  field={filter.template.field}
                  label={filter.template.name}
                  list={filter.template.listOptions}
                  type={filter.template.valueType}
                  value={filter.filter.value}
                  onDelete={() => {
                    const newFiltersApplied = [...filtersApplied];
                    newFiltersApplied.splice(i, 1);
                    setFiltersApplied(newFiltersApplied);
                  }}
                  onChange={(newFilter) => {
                    filter.filter.operator = newFilter.operator;
                    filter.filter.value = newFilter.value;
                    setFiltersApplied([...filtersApplied]);
                  }}
                />
              </Grid>
            );
          })}
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            xl={3}
            display="flex"
            alignItems="flex-end"
          >
            <Button
              sx={{ mb: 1 / 4, width: { xs: 1, md: "fit-content" } }}
              onClick={(e) => setAnchorEl(e.target)}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Filter
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 1, mb: 1 }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            color="error"
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button variant="contained" type="submit">
            Apply
          </Button>
        </Box>
      </Box>
      <Divider />
    </Collapse>
  );
}
