import { useGlobalContext } from "../../contexts/Global";

interface UnauthorizedProps {
  children: any;
}

export default function Unauthorized(props: UnauthorizedProps) {
  const { context } = useGlobalContext();
  if (!context.user) return props.children;
  else return null;
}
