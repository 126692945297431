import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import IconAvatar from "./IconAvatar";

interface TranslateButtonProps {
  aiResponseMode?: boolean;
  onTranslate?: () => any;
}

export default function TranslateButton(props: TranslateButtonProps) {
  return (
    <IconAvatar
      icon={GTranslateOutlinedIcon}
      size={30}
      onClick={props.onTranslate}
      tooltipTitle="Translate"
    />
  );
}
