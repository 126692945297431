import { useGlobalContext } from "../contexts/Global";
import { UserRole } from "../services/swagger";

export default function useRole() {
  const { context } = useGlobalContext();
  const rolesOrder = ["user", "agent", "admin"];

  const authorizedRoleIndex = rolesOrder.findIndex(
    (e) => e === context.user?.role
  );

  return (role: UserRole) => {
    const expectedRoleIndex = rolesOrder.findIndex((e) => e === role);
    if (authorizedRoleIndex < expectedRoleIndex) return false;
    else return true;
  };
}
