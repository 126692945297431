import { useEffect, useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { Stack } from "@mui/material";
import { SettingsService } from "../../../services/api";
import { useGlobalContext } from "../../../contexts/Global";
import useRefresh from "../../../hooks/useRefresh";

export default function SapSettingsPage() {
  const { context } = useGlobalContext();
  const refresh = useRefresh();
  const [enabled, setEnabled] = useState(false);
  const [database, setDatabase] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [server, setServer] = useState("");

  const handleSubmit = async () => {
    if (!context.settings) return;
    await SettingsService.updateSettings({
      appSettings: {
        ...context.settings,
        sap: {
          enabled,
          database,
          server,
          username,
          password,
        },
      },
    });
    refresh();
  };

  useEffect(() => {
    if (!context.settings) return;
    setEnabled(context.settings.sap.enabled);
    setServer(context.settings.sap.server);
    setUsername(context.settings.sap.username);
    setPassword(context.settings.sap.password);
    setDatabase(context.settings.sap.database);
  }, [context.settings]);

  return (
    <FormTemplate
      title="SAP"
      disableHeaderCloseButton
      disableCancelButton
      submitButtonText="Save"
      disableEnter
      onSubmit={handleSubmit}
    >
      <Stack spacing={2}>
        <FormInput
          field="enabled"
          label="Enabled"
          type="boolean"
          value={enabled}
          onChange={(value) => setEnabled(value as boolean)}
        />
        <FormInput
          field="server"
          label="Server"
          type="string"
          value={server}
          onChange={(value) => setServer(value.toString())}
        />
        <FormInput
          field="database"
          label="Database"
          type="string"
          value={database}
          onChange={(value) => setDatabase(value.toString())}
        />
        <FormInput
          field="username"
          label="Username"
          type="string"
          value={username}
          onChange={(value) => setUsername(value.toString())}
        />
        <FormInput
          field="password"
          label="Password"
          type="string"
          value={password}
          onChange={(value) => setPassword(value.toString())}
        />
      </Stack>
    </FormTemplate>
  );
}
