import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import FormInput from "../molecules/FormInput";
import { FormEvent, useState } from "react";
import { AuthService } from "../../services/api";
import { useGlobalContext } from "../../contexts/Global";
import { useNavigate } from "react-router";

interface EmailConfirmationDialogProps {
  open: boolean;
  login: string;
  password: string;
  handleOTP: () => void;
  handleClose: (success: boolean) => void;
}

export default function EmailConfirmationDialog(
  props: EmailConfirmationDialogProps
) {
  const { context, setContext } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    const res = await AuthService.login({
      loginBody: { login: props.login, password: props.password },
      emailCode: code,
    });
    setLoading(false);
    if (res?.data?.success) {
      props.handleClose(true);
      localStorage.setItem("authToken", res.data.data?.token || "");
      if (res?.data?.data?.otpRequired) {
        return props.handleOTP();
      }
      setContext({ ...context, user: res.data.data?.user });
      navigate("/");
    }
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Email Confirmation
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        <Typography mb={4}>
          Please enter the code that was sent to your email
        </Typography>
        <FormInput
          label="Confirmation Code"
          field="code"
          type="string"
          value={code}
          onChange={(value) => setCode(value as string)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          onClick={() => props.handleClose(false)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
