import { Box, Icon, useTheme } from "@mui/material";
import { SortDirection } from "../../services/swagger";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CSSProperties } from "react";

interface SortingArrowProps {
  sort: SortDirection;
  field: string;
  activeField: string;
  onClick: () => any;
}

function TestIcon({ style, color }: { color: string; style?: CSSProperties }) {
  return (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3.99V11H5V3.99H8L4 0L0 3.99H3Z" fill={color} />
    </svg>
  );
}

export default function SortingArrow(props: SortingArrowProps) {
  const theme = useTheme();
  const activeColor =
    props.field !== props.activeField || props.sort === SortDirection.None
      ? theme.palette.grey[400]
      : theme.palette.primary.main;
  const rotate =
    props.sort === SortDirection.Asc && props.activeField === props.field;
  return (
    <Box
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        height: 16,
        width: 13,
        position: "relative",
        transition: "transform 100ms ease-in-out",
        transform: rotate ? "rotate(180deg)" : "rotate(0deg)",
      }}
    >
      <TestIcon
        color={activeColor}
        style={{
          position: "absolute",
          rotate: "180deg",
          bottom: 0,
          left: 0,
        }}
      />
      <TestIcon
        color={theme.palette.grey[400]}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      />
    </Box>
  );
}
