import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  ButtonBaseProps,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { MouseEvent, useEffect, useState } from "react";
import NotificationInfo from "../molecules/NotificationInfo";
import { NotificationService } from "../../services/api";
import { NotificationInstance } from "../../services/swagger";
import useRefresh from "../../hooks/useRefresh";

export default function NotificationButton() {
  const increment = 5;
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationInstances, setNotificationInstances] = useState<
    NotificationInstance[]
  >([]);

  const loadNotifications = async () => {
    const res = await NotificationService.getMyNotifications({ limit });
    if (res?.data?.data) {
      setNotificationInstances(res.data.data.docs);
      setCount(res.data.data.count);
    }
  };

  useRefresh(loadNotifications);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearNotifications = async () => {
    const res = await NotificationService.updateNotificationsClear();
    if (res?.data?.success) loadNotifications();
  };

  useEffect(() => {
    if (!navigator.serviceWorker) return;
    navigator.serviceWorker.onmessage = (e) => {
      const { notification } = JSON.parse(e.data);
      if (!notification) return;
      loadNotifications();
    };
  }, []);

  useEffect(() => {
    loadNotifications();
  }, [limit]);

  return (
    <>
      <Tooltip title="Notifications">
        <ButtonBase sx={{ position: "relative" }} onClick={handleClick}>
          {notificationInstances.length > 0 && (
            <CircleIcon
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                transform: "translateX(35%) translateY(-35%)",
                zIndex: 2,
                width: 15,
                height: 15,
                color: (theme) => theme.palette.error.light,
              }}
            />
          )}
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: (theme) => theme.palette.primary.light,
              width: 40,
              height: 40,
            }}
          >
            <NotificationsNoneIcon
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            />
          </Avatar>
        </ButtonBase>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            width: 300,
            bgcolor: "background.default",
            overflow: "auto",
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notificationInstances.length > 0 ? (
          <>
            {notificationInstances.map((instance, i) => {
              return (
                <NotificationInfo
                  key={i}
                  notification={instance.notification}
                  date={instance.date}
                />
              );
            })}
            {limit < count && (
              <Button fullWidth onClick={() => setLimit((l) => l + increment)}>
                Load more...
              </Button>
            )}
            <Divider sx={{ mb: 1 }} />
            <Box sx={{ textAlign: "right", pr: 1 }}>
              <Button onClick={handleClearNotifications}>
                Clear Notifications
              </Button>
            </Box>
          </>
        ) : (
          <Typography mt={1} mb={1} align="center" color="text.secondary">
            No notifications found
          </Typography>
        )}
      </Menu>
    </>
  );
}
