interface StepperContentProps {
  step: number;
  activeStep: number;
  children?: any;
}

export default function StepperContent(props: StepperContentProps) {
  if (props.activeStep !== props.step) return null;
  return props.children;
}
