import { Box, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import useDownload from "../../hooks/useDownload";
import Download from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";

interface TransformImageProps {
  src: string;
  fullscreen: boolean;
}

interface ImageDialogProps {
  open: boolean;
  downloadPath?: string;
  src: string;
  filename: string;
  onClose?: () => void;
}

function TransformImage(props: TransformImageProps) {
  const { centerView } = useControls();
  useEffect(centerView, [props.fullscreen]);
  return (
    <TransformComponent
      wrapperClass="zoomable-image"
      wrapperStyle={{
        height: "100%",
        width: "100%",
      }}
    >
      <img
        src={props.src}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
        }}
      />
    </TransformComponent>
  );
}

export default function ImageDialog(props: ImageDialogProps) {
  const { open, src, downloadPath, filename } = props;
  const [fullscreen, setFullscreen] = useState(false);

  const { download, DownloadLink } = useDownload();

  const handleDownload = () => {
    if (downloadPath) download(downloadPath);
  };

  const handleClose = () => {
    setFullscreen(false);
    props.onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DownloadLink />
      <Box
        height={1}
        display="flex"
        flexDirection="column"
        minHeight={400}
        minWidth={400}
      >
        <Box
          sx={{ p: 2, boxSizing: "border-box" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight={600}>Image {filename}</Typography>
          <Box display="flex" alignItems="center">
            {downloadPath && (
              <IconButton onClick={handleDownload} color="primary">
                <Download />
              </IconButton>
            )}
            <IconButton
              onClick={() => setFullscreen((e) => !e)}
              color="warning"
            >
              {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <IconButton onClick={handleClose} color="error">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          p={1}
          flexGrow={1}
        >
          <TransformWrapper disablePadding centerOnInit>
            {({ ...rest }) => (
              <TransformImage src={src} fullscreen={fullscreen} />
            )}
          </TransformWrapper>
        </Box>
      </Box>
    </Dialog>
  );
}
