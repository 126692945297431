import { Chip } from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers/string";
import { TicketPriorityEnum } from "../../services/swagger";

interface PriorityChipProps {
  priority: TicketPriorityEnum;
}

export default function PriorityChip(props: PriorityChipProps) {
  const priority = props.priority;
  return (
    <Chip
      sx={{ width: 1 }}
      color={
        (priority === "high" && "error") ||
        (priority === "medium" && "success") ||
        undefined
      }
      label={capitalizeFirstLetter(priority)}
    />
  );
}
