import { AppBar, Box, Stack } from "@mui/material";
import NotificationButton from "./NotificationButton";

import TitleWithLogo from "../molecules/TitleWithLogo";
import MenuButton from "../atoms/MenuButton";
import { sideBarWidth } from "./SideBar";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";

export default function TopBar() {
  const { context, setContext } = useGlobalContext();
  const breakpoint = useBreakpoint();
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "none",
      }}
    >
      <Box display="flex" alignItems="center" p={2} boxSizing="border-box">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          boxSizing="border-box"
          pr={4}
          width={breakpoint.md ? sideBarWidth : 30}
        >
          {breakpoint.md && <TitleWithLogo />}
          {!breakpoint.md && (
            <MenuButton
              onClick={() =>
                setContext({ ...context, sidebar: !context.sidebar })
              }
            />
          )}
        </Box>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          {!breakpoint.md && <TitleWithLogo />}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <NotificationButton />
        </Box>
      </Box>
    </AppBar>
  );
}
