import { Box, Stack, TextField } from "@mui/material";
import { Filter, FilterValueType, Operator } from "../../services/swagger";
import Dropdown, { DropDownItem } from "./Dropdown";
import { useEffect, useState } from "react";
import DeleteButton from "../atoms/DeleteButton";
import OperatorSelectorButton from "../atoms/OperatorSelectorButton";
import FormInput from "./FormInput";

interface EndAdornmentProps {
  type: FilterValueType;
  onChange: (operator: Operator) => void;
  onDelete: () => void;
}

interface FilterInputProps {
  label: string;
  field: string;
  type: FilterValueType;
  list?: DropDownItem[];
  onDelete: () => void;
  onChange: (filter: Filter) => void;
  value: string | number | boolean;
}

type AllowedOperators = {
  [key in FilterValueType]: Operator[];
};

export const allowedOperators: AllowedOperators = {
  id: ["eq", "ne"],
  boolean: ["eq", "ne"],
  date: ["gt", "gte", "lt", "lte"],
  list: ["eq", "ne"],
  number: ["eq", "ne", "gt", "gte", "lt", "lte"],
  string: ["regex", "eq", "ne"],
};

const booleans: DropDownItem[] = [
  { name: "True", value: true },
  { name: "False", value: false },
];

function EndAdornment(props: EndAdornmentProps) {
  return (
    <Stack spacing={1} direction="row" alignItems="center" sx={{ ml: 1 }}>
      <OperatorSelectorButton
        type={props.type}
        onChange={(newOperator) => {
          props.onChange(newOperator);
        }}
      />
      <DeleteButton onClick={props.onDelete} />
    </Stack>
  );
}

export default function FilterInput(props: FilterInputProps) {
  const [operator, setOperator] = useState<Operator>(
    allowedOperators[props.type][0]
  );

  const [value, setValue] = useState<any>();
  const handleChange = () => {
    props.onChange({
      field: props.field,
      operator,
      type: props.type,
      value,
    });
  };

  useEffect(() => {
    handleChange();
  }, [operator, value]);

  if (props.type === FilterValueType.List)
    return (
      <FormInput
        type="list"
        field={props.field}
        label={props.label}
        placeholder="Any"
        value={props.value}
        list={props.list || []}
        endAdornment={
          <EndAdornment
            type={props.type}
            onChange={(newOperator) => setOperator(newOperator)}
            onDelete={props.onDelete}
          />
        }
        onChange={(value) => setValue((value as string) || undefined)}
      />
    );
  else if (props.type === FilterValueType.Boolean)
    return (
      <FormInput
        type="boolean"
        field={props.field}
        label={props.label}
        endAdornment={
          <EndAdornment
            type={props.type}
            onChange={(newOperator) => setOperator(newOperator)}
            onDelete={props.onDelete}
          />
        }
        onChange={(value) =>
          setValue(value === undefined ? undefined : (value as boolean))
        }
      />
    );
  else
    return (
      <FormInput
        field={props.field}
        label={props.label}
        placeholder={props.label}
        value={props.value}
        endAdornment={
          <Box>
            <EndAdornment
              type={props.type}
              onChange={(newOperator) => setOperator(newOperator)}
              onDelete={props.onDelete}
            />
          </Box>
        }
        type={props.type}
        inputType={props.type}
        onChange={(value) => setValue(value as string)}
      />
    );
}
