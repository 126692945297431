import { useNavigate } from "react-router";
import { useGlobalContext } from "../../contexts/Global";
import { FormEvent, useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import FormInput from "../molecules/FormInput";
import { Link } from "react-router-dom";
import { AuthService } from "../../services/api";
import ActivateAccountDialog from "./ActivateAccountDialog";
import EmailConfirmationDialog from "./EmailConfirmationDialog";
import OTPDialog from "./OTPDialog";

export default function LoginForm() {
  const { context, setContext } = useGlobalContext();
  const navigate = useNavigate();
  const [activateDialog, setActivateDialog] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [otpDialog, setOtpDialog] = useState(false);
  const [emailConfirmationDialog, setEmailConfirmationDialog] = useState(false);

  const handleLogin = async (e?: FormEvent) => {
    e?.preventDefault();
    const res = await AuthService.login({
      loginBody: { login, password },
    });
    if (res?.data?.data?.mustActivate) {
      setActivateDialog(true);
      return;
    } else if (!res?.data?.success) {
      if (res?.data?.data?.emailConfirmationRequired)
        setEmailConfirmationDialog(true);
      return;
    }
    localStorage.setItem("authToken", res.data.data?.token || "");
    if (res?.data?.data?.otpRequired) {
      setOtpDialog(true);
      return;
    }
    setContext({ ...context, user: res.data.data?.user });
    navigate("/");
  };

  const handleCloseActivateDialog = (success: boolean) => {
    setActivateDialog(false);
    if (success) handleLogin();
  };

  return (
    <Paper
      sx={{
        bgcolor: "background.default",
        pt: 2,
        p: { xs: 4, md: 8 },
        maxWidth: { xs: 350, sm: 500, md: 560 },
        minWidth: { xs: 300, sm: 450, md: 560 },
        borderRadius: { xs: 0, md: 8 },
      }}
      component="form"
      onSubmit={handleLogin}
    >
      <EmailConfirmationDialog
        handleOTP={() => setOtpDialog(true)}
        handleClose={() => setEmailConfirmationDialog(false)}
        login={login}
        password={password}
        open={emailConfirmationDialog}
      />
      <OTPDialog handleClose={() => setOtpDialog(false)} open={otpDialog} />
      <ActivateAccountDialog
        open={activateDialog}
        handleClose={handleCloseActivateDialog}
      />
      <Typography color="primary" variant="h2" fontWeight="bold" fontSize={32}>
        Welcome
      </Typography>
      <Typography fontWeight="light" mb={4}>
        Please login to your account here!
      </Typography>
      <Stack spacing={1}>
        <FormInput
          placeholder="Username or Email"
          type="string"
          required
          field="login"
          label="Login"
          value={login}
          onChange={(v) => setLogin(v as string)}
        />
        <FormInput
          inputType="password"
          type="string"
          required
          field="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(v) => setPassword(v as string)}
        />
        <Box textAlign="right">
          <Link to="/account/forgot-password">Forgot password?</Link>
        </Box>
      </Stack>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, borderRadius: 12, height: 50, mb: 2 }}
      >
        Login
      </Button>
      <Box>
        <Typography fontWeight="light">
          Don't have an account yet?{" "}
          <Typography
            onClick={() => navigate("/register")}
            color="primary"
            component="span"
            fontSize={16}
            ml={4}
            sx={{ cursor: "pointer" }}
          >
            <u>Register</u>
          </Typography>
        </Typography>
      </Box>
    </Paper>
  );
}
