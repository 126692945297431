import MUI, { Autocomplete, Box, InputBase, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export interface DropDownItem {
  value: string | number | boolean;
  name: string;
}

interface DropdownProps
  extends Omit<
    MUI.AutocompleteProps<any, false, true, true>,
    | "options"
    | "renderInput"
    | "getOptionLabel"
    | "renderOption"
    | "disableClearable"
  > {
  unselectedValue?: string;
  placeholder?: string;
  items: DropDownItem[];
  endAdornment?: JSX.Element;
  required?: boolean;
}

export default function Dropdown(props: DropdownProps) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      {...props}
      disableClearable={props.required ? true : undefined}
      options={props.items}
      getOptionLabel={(option: string | DropDownItem) => {
        if (typeof option === "string") return option;
        return option.name ?? "";
      }}
      renderInput={(params) => {
        let endAdornment: any = params.InputProps.endAdornment;
        let extraPadding = 4;
        if (props.endAdornment) extraPadding = 80;
        endAdornment = (
          <Box display="flex" position="absolute" right={16}>
            {endAdornment.props.children}
            {props.endAdornment}
          </Box>
        );
        return (
          <InputBase
            {...params}
            required={props.required}
            inputProps={{
              ...params.inputProps,
              style: {
                ...params.inputProps.style,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 4,
                paddingRight: extraPadding,
              },
            }}
            endAdornment={endAdornment}
            placeholder={props.placeholder || props.unselectedValue}
            className={params.InputProps.className}
            ref={params.InputProps.ref}
            startAdornment={params.InputProps.startAdornment}
          />
        );
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
}
