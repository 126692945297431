import { Box, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import useBreakpoint from "../../hooks/useBreakpoint";

interface DateAndNameProps {
  date?: string | number;
  name?: string;
}

export default function DateAndName(props: DateAndNameProps) {
  const breakpoint = useBreakpoint();
  return (
    <Box>
      <Stack
        display="flex"
        direction={breakpoint.md ? "row" : "column"}
        spacing={breakpoint.md ? 2 : 1}
      >
        {props.date && (
          <Typography
            color="text.secondary"
            display="flex"
            alignItems="center"
            fontWeight={600}
          >
            <AccessTimeFilledIcon fontSize="small" sx={{ mr: 1 / 2 }} />
            {new Date(props.date as any).toLocaleString("tr")}
          </Typography>
        )}
        {props.name && (
          <Typography
            color="primary"
            display="flex"
            alignItems="center"
            variant="button"
            fontWeight={600}
          >
            <PersonIcon fontSize="small" sx={{ mr: 1 / 2 }} />
            {props.name}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
