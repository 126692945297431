import {
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useConfirm from "../../hooks/useConfirm";
import { TaskService, UserService } from "../../services/api";
import {
  Task,
  Ticket,
  UpdateTaskStatusBodyStatusEnum,
  User,
} from "../../services/swagger";
import FormTemplate from "../templates/FormTemplate";
import useRefresh from "../../hooks/useRefresh";
import FormInput from "../molecules/FormInput";
import { useEffect, useState } from "react";
import { DropDownItem } from "../molecules/Dropdown";
import DeleteButton from "../atoms/DeleteButton";
import AddButton from "../atoms/AddButton";
import { formatMilliseconds } from "../../helpers/date";

interface TaskDialogProps {
  task: Task;
  ticket?: Ticket;
  open: boolean;
  onClose?: () => void;
}

export default function TaskDialog(props: TaskDialogProps) {
  const { task, ticket } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState("");
  const [userToAssign, setUserToAssign] = useState("");
  const [allUsers, setAllUsers] = useState<DropDownItem[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const { confirm, ConfirmDialog } = useConfirm();
  const refresh = useRefresh();

  const handleAssignUser = async () => {
    if (!task._id) return;
    const res = await TaskService.createTaskUser({
      id: task._id,
      createTaskUserBody: {
        userId: userToAssign,
      },
    });
    if (res?.data?.success) {
      setUserToAssign("");
      refresh();
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (!task._id) return;
    const res = await TaskService.deleteTaskUser({
      id: task._id,
      userId,
    });
    if (res?.data?.success) refresh();
  };

  const handleCancel = async () => {
    if (!task._id) return;
    const res = await TaskService.updateTask({
      id: task._id,
      updateTaskBody: {
        cancelled: true,
        comment: task.comment,
        description: task.description,
        title: task.title,
      },
    });
    if (res?.data?.success) refresh();
  };

  const handleSubmit = async () => {
    if (!task._id) return;
    const res = await TaskService.updateTask({
      id: task._id,
      updateTaskBody: {
        cancelled: task.cancelled,
        comment: comment,
        description: description,
        title: title,
      },
    });
    if (res?.data?.success) {
      refresh();
      handleClose();
    }
  };

  const handleDelete = async () => {
    if (!task._id) return;
    const res = await TaskService.deleteTask({
      id: task._id,
    });
    if (res?.data?.success) refresh();
  };

  const handleUpdateStatus = async (
    newStatus: UpdateTaskStatusBodyStatusEnum
  ) => {
    if (!task._id) return;
    const res = await TaskService.updateTaskStatus({
      id: task._id,
      updateTaskStatusBody: {
        status: newStatus,
      },
    });
    if (res?.data?.success) refresh();
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const loadUsers = async () => {
    const res = await UserService.getUserList({
      limit: Number.MAX_VALUE,
      skip: 0,
    });
    if (!res?.data?.data) return;
    const arr: DropDownItem[] = [];
    res.data.data.docs.forEach((user) => {
      if (!user._id) return;
      arr.push({
        name: user.name,
        value: user._id,
      });
    });
    setAllUsers(arr);
  };

  useEffect(() => {
    if (!props.open) return;
    loadUsers();
    setTitle(task.title);
    setDescription(task.description);
    setComment(task.comment);
    setUsers(task.users);
  }, [props.open]);

  return (
    <Dialog
      PaperProps={{ sx: { minWidth: { xs: 350, md: 600 } } }}
      open={props.open}
      onClose={handleClose}
      keepMounted={false}
    >
      <ConfirmDialog />
      <FormTemplate
        title={`${task.title} [${new Date(
          task.date as unknown as string
        ).toLocaleString("tr")}]`}
        confirm
        onSubmit={handleSubmit}
        onCancel={handleClose}
      >
        <Stack spacing={2}>
          {task.cancelled && (
            <Typography fontWeight={600}>
              Cancelled:{" "}
              {new Date(task.cancelDate as unknown as string).toLocaleString(
                "tr"
              )}
            </Typography>
          )}
          {task.startDate && (
            <Typography fontWeight={600}>
              Started:{" "}
              {new Date(task.startDate as unknown as string).toLocaleString(
                "tr"
              )}
            </Typography>
          )}
          {task.startDate && task.endDate && (
            <Typography fontWeight={600}>
              Ended:{" "}
              {new Date(task.endDate as unknown as string).toLocaleString("tr")}{" "}
              ({formatMilliseconds(task.endDate - task.startDate)})
            </Typography>
          )}
          <FormInput
            field="title"
            disabled={task.cancelled || Boolean(task.endDate)}
            value={title}
            label="Title"
            onChange={(v) => setTitle(v as string)}
            type="string"
          />
          <FormInput
            field="description"
            disabled={task.cancelled || Boolean(task.endDate)}
            value={description}
            label="Description"
            onChange={(v) => setDescription(v as string)}
            type="string"
            multiline
            rows={4}
          />

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, p: 0 }}>Name</TableCell>
                  <TableCell
                    sx={{ fontWeight: 600, p: 0 }}
                    align="center"
                    width={40}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {task.users.map((user) => {
                  return (
                    <TableRow key={user._id} hover>
                      <TableCell sx={{ p: 0, pt: 1, pb: 1 }}>
                        {user.name}
                      </TableCell>
                      <TableCell align="center" sx={{ p: 0, pt: 1, pb: 1 }}>
                        {!task.cancelled && !task.startDate && (
                          <DeleteButton
                            onClick={() =>
                              confirm(() => {
                                if (!user._id) return;
                                handleDeleteUser(user._id);
                              })
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell sx={{ p: 0, pt: 1, pb: 1, pr: 1 }}>
                    <FormInput
                      label=""
                      disabled={task.cancelled || Boolean(task.endDate)}
                      field="newUser"
                      list={allUsers}
                      value={userToAssign}
                      type="list"
                      placeholder="Select user to assign..."
                      onChange={(v) => setUserToAssign(v as string)}
                    />
                  </TableCell>
                  <TableCell sx={{ p: 0, pt: 1.5, pb: 1 }} align="center">
                    {!task.cancelled && (
                      <AddButton onClick={() => confirm(handleAssignUser)} />
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <FormInput
            field="comment"
            value={comment}
            label="Comments"
            onChange={(v) => setComment(v as string)}
            type="string"
            placeholder="ex. Task needs re-evaluation at time X"
            multiline
            rows={4}
          />
          <Box>
            <Grid container spacing={2}>
              {!task.cancelled && !task.startDate && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    onClick={() => confirm(handleCancel)}
                  >
                    Cancel Task
                  </Button>
                </Grid>
              )}
              {!task.startDate && (
                <Grid item xs={task.cancelled ? 12 : 6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={() => confirm(handleDelete)}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
              {!task.cancelled && !task.endDate && (
                <Grid item xs={12}>
                  {!task.startDate ? (
                    <Button
                      variant="contained"
                      fullWidth
                      color="success"
                      onClick={() => confirm(() => handleUpdateStatus("start"))}
                    >
                      Start
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      color="warning"
                      onClick={() => confirm(() => handleUpdateStatus("end"))}
                    >
                      Stop
                    </Button>
                  )}
                </Grid>
              )}
              {!task.cancelled && !task.endDate && (
                <Grid item xs={12}>
                  {!task.startDate && !task.endDate && (
                    <AlreadyStarted task={task} />
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}

function AlreadyStarted(props: { task: Task }) {
  const { task } = props;
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const refresh = useRefresh();

  useEffect(() => {
    if (!open) return;
    setStartDate("");
    setEndDate("");
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!task._id) return;
    const res = await TaskService.updateTask({
      id: task._id,
      updateTaskBody: {
        cancelled: false,
        comment: task.comment,
        description: task.description,
        title: task.title,
        startDate: new Date(startDate).getTime() || undefined,
        endDate: new Date(endDate).getTime() || undefined,
      },
    });
    if (res?.data?.success) {
      refresh();
      handleClose();
    }
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Already Started?
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <FormTemplate
          title="Start & End Dates"
          noForm
          confirm
          submitButtonText="Set"
          onSubmit={handleSubmit}
          onCancel={handleClose}
        >
          <FormInput
            field="startDate"
            label="Start Date"
            type="date"
            value={startDate}
            required
            inputType="datetime-local"
            onChange={(v) => setStartDate(v as string)}
          />
          <FormInput
            field="endDate"
            label="End Date"
            type="date"
            value={endDate}
            inputType="datetime-local"
            onChange={(v) => setEndDate(v as string)}
          />
        </FormTemplate>
      </Dialog>
    </>
  );
}
