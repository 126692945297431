interface OfficeMapProps {
  width?: number;
  height?: number;
}

export default function OfficeMap(props: OfficeMapProps) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3450.3360454918075!2d33.34968097491989!3d35.19084127018318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de173484a19d17%3A0x669190971631aa1f!2sAk%C3%A7aba%20Communications!5e0!3m2!1sen!2str!4v1702514273603!5m2!1sen!2str"
      width={props.width || "100%"}
      height={props.height || "100%"}
      style={{ border: 0, borderRadius: 25 }}
      allowFullScreen
      loading="lazy"      
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
