import MainTemplate from "../templates/MainTemplate";
import LoginForm from "../organisms/LoginForm";
import { Box } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";

export default function LoginPage() {
  const breakpoint = useBreakpoint();
  return (
    <MainTemplate>
      <Box
        py={8}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <img
          style={{
            position: "absolute",
            height: 1200,
            width: 1200,
            left: -600,
            bottom: -200,
          }}
          src={require("../../assets/grey-circles.png")}
        />
        <Box ml={breakpoint.md ? 20 : 0} zIndex={2} position="relative">
          {breakpoint.md && (
            <img
              style={{
                position: "absolute",
                height: 500,
                width: 340,
                objectFit: "cover",
                left: -300,
                zIndex: 3,
              }}
              src={require("../../assets/guy-sitting.png")}
            />
          )}
          <LoginForm />
        </Box>
      </Box>
    </MainTemplate>
  );
}
