export function generateV1UUID() {
  function getRandomValues(arr: any[]) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = Math.floor(Math.random() * 256);
    }
    return arr;
  }

  // Buffer to hold the UUID components
  const buf = new Array(16);
  getRandomValues(buf);

  // Timestamp
  const now = Date.now();
  const timeLow = ((now & 0xfffffff) * 10000) % 0x100000000;
  const timeMid = ((now / 0x100000000) & 0xfffffff) * 10000;
  const timeHighAndVersion =
    (((now / 0x1000000000000) & 0xfff) * 10000) | 0x1000; // version 1

  // Random clock sequence
  const clockSeq = (buf[8] & 0x3f) | 0x80; // clock sequence with variant (10xx)

  // Random node (48 bits)
  const node = [
    buf[10] | 0x01, // Multicast bit, prevents collision with MAC addresses
    buf[11],
    buf[12],
    buf[13],
    buf[14],
    buf[15],
  ];

  // Convert to hexadecimal and pad with zeros if necessary
  function hex(num: number, length: number) {
    let str = num.toString(16);
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  }

  const uuid = [
    hex(timeLow, 8),
    hex(timeMid, 4),
    hex(timeHighAndVersion, 4),
    hex(clockSeq, 2) + hex(buf[9], 2),
    node.map((b) => hex(b, 2)).join(""),
  ].join("-");

  return uuid;
}
