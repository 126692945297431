import axios from "axios";
import {
  AuthenticationApiFactory,
  Configuration,
  FirewallApiFactory,
  ItemApiFactory,
  LogApiFactory,
  OpenAIApiFactory,
  FTPApiFactory,
  SAPApiFactory,
  SettingsApiFactory,
  SubscriptionsApiFactory,
  TicketsApiFactory,
  UsersApiFactory,
  VouchersApiFactory,
  TasksApiFactory,
  NotificationsApiFactory,
  DashboardApiFactory,
  DepartmentsApiFactory,
} from "./swagger";
import settings from "../settings";

const configuration = new Configuration();

export const LogService = LogApiFactory(configuration, settings.baseUrl, axios);

export const AuthService = AuthenticationApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const DepartmentService = DepartmentsApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const CalendarService = LogApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const FirewallService = FirewallApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const ItemService = ItemApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const NotificationService = NotificationsApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const OpenAiService = OpenAIApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const FtpService = FTPApiFactory(configuration, settings.baseUrl, axios);

export const SapService = SAPApiFactory(configuration, settings.baseUrl, axios);

export const SettingsService = SettingsApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const SubscriptionService = SubscriptionsApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const TicketService = TicketsApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const TaskService = TasksApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const UserService = UsersApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const DashboardService = DashboardApiFactory(
  configuration,
  settings.baseUrl,
  axios
);

export const VoucherService = VouchersApiFactory(
  configuration,
  settings.baseUrl,
  axios
);
