interface FormatMillisecondsOptions {
  ignoreMilliseconds?: boolean;
}

export function formatMilliseconds(
  milliseconds: number,
  options?: FormatMillisecondsOptions
): string {
  let ms = milliseconds % 1000;
  let seconds = Math.floor((milliseconds / 1000) % 60);
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

  let formattedTime: string[] = [];

  if (days > 0) {
    formattedTime.push(`${days}d`);
  }

  if (hours > 0) {
    formattedTime.push(`${hours.toString()}h`);
  }

  if (minutes > 0) {
    formattedTime.push(`${minutes.toString()}m`);
  }

  if (seconds > 0) {
    let str = `${seconds.toString()}`;
    if (!options?.ignoreMilliseconds)
      str += `.${ms.toString().padStart(3, "0")}`;
    str += `s`;
    formattedTime.push(str);
  }

  return formattedTime.join(", ");
}
