import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverPosition,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ManagementTemplate from "../templates/ManagementTemplate";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { FtpService } from "../../services/api";
import { FileInfo } from "../../services/swagger";
import FolderIcon from "@mui/icons-material/Folder";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import SearchIcon from "@mui/icons-material/Search";
import useConfirm from "../../hooks/useConfirm";
import useDownload from "../../hooks/useDownload";
import useBreakpoint from "../../hooks/useBreakpoint";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import UploadButton from "../organisms/UploadButton";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useSearchParams } from "react-router-dom";

function normalizeFTPPath(inputPath: string): string {
  // Remove consecutive slashes
  const cleanedPath = inputPath.replace(/\/+/g, "/");

  // Ensure the path starts with a single slash
  const absolutePath = cleanedPath.startsWith("/")
    ? cleanedPath
    : "/" + cleanedPath;

  // Ensure it's a valid FTP path
  const ftpPath = `${absolutePath}`;

  return ftpPath;
}

export function formatBytes(bytes: number) {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

export default function StoragePage() {
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const { download, DownloadLink } = useDownload();
  const [createDirectory, setCreateDirectory] = useState(false);
  const [search, setSearch] = useState("");
  const [pathText, setPathText] = useState("");
  const remoteFiles = useRef<FileInfo[]>([]);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | null>(
    null
  );
  const [currentFile, setCurrentFile] = useState<FileInfo | null>();
  const [renameFile, setRenameFile] = useState<FileInfo | null>();
  const [name, setName] = useState("");

  const path = searchParams.get("path") || "/";

  const { confirm: confirmDownload, ConfirmDialog: DownloadConfirmDialog } =
    useConfirm({
      title: "Download",
      text: "Do you want to proceed downloading this file?",
    });

  const handleClickCreateDirectory = () => {
    setName("");
    setCreateDirectory(true);
  };

  const handleCreateDirectory = () => {
    confirm(async () => {
      let paths = path.split("/");
      paths = [...paths, name];
      const folderpath = normalizeFTPPath(paths.join("/"));
      const res = await FtpService.createDirectory({ path: folderpath });
      if (!res?.data?.success) return;
      handleCancelCreateDirectory();
      loadFiles();
    });
  };

  const handleCancelCreateDirectory = () => {
    setCreateDirectory(false);
    setName("");
  };

  const handleRightClick = (e: MouseEvent, file: FileInfo) => {
    if (renameFile?.name === file.name) return;
    if (createDirectory) {
      setCreateDirectory(false);
      return;
    }
    e.preventDefault();
    handleCancelRename();
    setAnchorPosition({ left: e.clientX, top: e.clientY });
    setCurrentFile(file);
  };

  const handleCloseMenu = () => {
    setAnchorPosition(null);
    setCurrentFile(null);
  };

  const handleGoBack = () => {
    if (path === "/") return;
    let paths = path.split("/");
    paths.splice(paths.length - 1, 1);
    setSearchParams((prev) => {
      prev.set("path", normalizeFTPPath(paths.join("/")));
      return prev;
    });
  };

  const handleRowClick = (file: FileInfo) => {
    if (createDirectory) {
      setCreateDirectory(false);
      return;
    }
    if (renameFile) {
      if (renameFile.name !== file.name) setRenameFile(null);
      return;
    }
    // if it's file
    if (file.type === 1) {
      let paths = path.split("/");
      paths = [...paths, file.name];
      const filePath = normalizeFTPPath(paths.join("/"));
      confirmDownload(() => {
        download(filePath);
      });
    } else if (file.type === 2) {
      let paths = path.split("/");
      paths = [...paths, file.name];

      setSearchParams((prev) => {
        prev.set("path", normalizeFTPPath(paths.join("/")));
        return prev;
      });
    }
  };

  const handleSearch = () => {
    setFiles(
      remoteFiles.current.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const handleSetPath = () => {
    let finalPath = pathText;
    if (!finalPath.startsWith("/")) finalPath = "/" + pathText;
    if (finalPath.endsWith("/") && finalPath.length > 1)
      finalPath = finalPath.substring(0, finalPath.length - 1);

    // Remove consecutive slashes
    finalPath = finalPath.replace(/\/+/g, "/");

    setSearchParams((prev) => {
      prev.set("path", finalPath);
      return prev;
    });
  };

  const loadFiles = async () => {
    const res = await FtpService.getFileList({ path });
    if (res?.data?.data) {
      remoteFiles.current = [
        ...res.data.data.filter((e) => e.type === 2),
        ...res.data.data.filter((e) => e.type === 1),
      ];
      setFiles(remoteFiles.current);
      setSearch("");
    }
  };

  useEffect(() => {
    setPathText(path);
    loadFiles();
  }, [path]);

  const { confirm: confirm, ConfirmDialog: ConfirmDialog } = useConfirm();

  const handleCancelRename = () => {
    setRenameFile(null);
    setName("");
  };

  const handleRename = async () => {
    confirm(async () => {
      if (!renameFile) return;
      let paths = path.split("/");
      const oldPath = normalizeFTPPath([...paths, renameFile.name].join("/"));
      const newPath = normalizeFTPPath([...paths, name].join("/"));
      const res = await FtpService.updateFilename({ oldPath, newPath });
      if (!res?.data?.success) return;
      handleCancelRename();
      loadFiles();
    });
  };

  const handleDelete = async () => {
    if (!currentFile) return;
    let paths = path.split("/");
    paths = [...paths, currentFile.name];
    const finalpath = normalizeFTPPath(paths.join("/"));
    if (currentFile.type === 2) {
      await FtpService.deleteDirectory({ path: finalpath });
    } else if (currentFile.type === 1) {
      await FtpService.deleteFile({ path: finalpath });
    }
    loadFiles();
  };

  const handleClickRename = (e: MouseEvent) => {
    e.preventDefault();
    if (!currentFile) return;
    setRenameFile({ ...currentFile });
    handleCloseMenu();
    setName(currentFile.name);
  };

  const handleClickDelete = (e: MouseEvent) => {
    e.preventDefault();
    confirm(handleDelete);
    handleCloseMenu();
  };

  return (
    <ManagementTemplate paper>
      <Menu
        onContextMenu={(e) => {
          e.preventDefault();
          handleCloseMenu();
        }}
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus
        disableRestoreFocus
        MenuListProps={{ sx: { bgcolor: "background.default" } }}
        onClose={handleCloseMenu}
        open={Boolean(currentFile)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition ?? { top: -999, left: -999 }}
      >
        <MenuItem onClick={handleClickRename}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <DownloadLink />
      <DownloadConfirmDialog />
      <ConfirmDialog />
      <Box height={1} display="flex" flexDirection="column">
        <Box
          p={2}
          display="flex"
          boxSizing="border-box"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={500}>FTP Storage</Typography>
          <Stack spacing={2} direction="row">
            <Button
              startIcon={<CreateNewFolderIcon />}
              variant="outlined"
              onClick={handleClickCreateDirectory}
            >
              New Folder
            </Button>
            <UploadButton path={path} onSuccess={loadFiles} />
          </Stack>
        </Box>
        <Divider />
        <Grid container>
          {breakpoint.md && (
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" borderRadius="inherit">
                <InputBase
                  placeholder="Path"
                  fullWidth
                  value={pathText}
                  onChange={(e) =>
                    setPathText(normalizeFTPPath(e.target.value))
                  }
                  size="small"
                  sx={{ pl: 2, flexGrow: 1 }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") handleSetPath();
                  }}
                />
                <Box height={1} borderRadius="inherit">
                  <Button
                    onClick={handleSetPath}
                    variant="contained"
                    sx={{
                      height: 50,
                      width: 70,
                      borderRadius: 0,
                    }}
                  >
                    <TurnRightIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" borderRadius="inherit">
              <InputBase
                placeholder="Search"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                sx={{ pl: 2, flexGrow: 1 }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") handleSearch();
                }}
              />
              <Box height={1}>
                <Button
                  onClick={handleSearch}
                  variant="contained"
                  sx={{
                    height: 50,
                    width: 70,
                    borderRadius: 0,
                  }}
                >
                  <SearchIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer
          sx={{ flexGrow: 1, overflow: "auto" }}
          onContextMenu={(e) => e.preventDefault()}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell width={100} align="right">
                  Size
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                <TableRow
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={handleGoBack}
                >
                  <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FolderIcon
                        sx={{ color: (theme) => theme.palette.warning.light }}
                      />
                      <Box>..</Box>
                    </Stack>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {createDirectory && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FolderIcon
                          sx={{
                            color: (theme) => theme.palette.warning.light,
                          }}
                        />
                        <Box flexGrow={1}>
                          <TextField
                            onKeyDown={(e) => {
                              if (e.code === "Enter") handleCreateDirectory();
                              if (e.code === "Escape")
                                handleCancelCreateDirectory();
                            }}
                            inputProps={{
                              style: {
                                paddingLeft: 4,
                              },
                            }}
                            InputProps={{
                              sx: { borderRadius: 0 },
                              endAdornment: (
                                <Stack direction="row" spacing={1} p={0.5}>
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={handleCreateDirectory}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={handleCancelCreateDirectory}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Stack>
                              ),
                            }}
                            size="small"
                            fullWidth
                            placeholder="New Folder"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
                {files.map((file) => {
                  return (
                    <TableRow
                      key={file.name}
                      hover
                      sx={{
                        cursor: "pointer",
                        bgcolor:
                          file === currentFile
                            ? "background.paper"
                            : "background.default",
                      }}
                      onClick={() => handleRowClick(file)}
                      onContextMenu={(e) => {
                        handleRightClick(e, file);
                      }}
                    >
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          {file.type === 2 ? (
                            <FolderIcon
                              sx={{
                                color: (theme) => theme.palette.warning.light,
                              }}
                            />
                          ) : (
                            <InsertDriveFileIcon
                              sx={{
                                color: (theme) => theme.palette.grey[500],
                              }}
                            />
                          )}
                          <Box flexGrow={1}>
                            {renameFile?.name === file.name ? (
                              <TextField
                                onKeyDown={(e) => {
                                  if (e.code === "Enter") handleRename();
                                  if (e.code === "Escape") handleCancelRename();
                                }}
                                inputProps={{
                                  style: {
                                    paddingLeft: 4,
                                  },
                                }}
                                InputProps={{
                                  sx: { borderRadius: 0 },
                                  endAdornment: (
                                    <Stack direction="row" spacing={1} p={0.5}>
                                      <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={handleRename}
                                      >
                                        <CheckIcon />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        size="small"
                                        onClick={handleCancelRename}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Stack>
                                  ),
                                }}
                                size="small"
                                fullWidth
                                placeholder={renameFile.name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            ) : (
                              file.name
                            )}
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        {file.type === 0 && "Unknown"}
                        {file.type === 1 && formatBytes(file.size)}
                        {file.type === 2 && "Folder"}
                        {file.type === 3 && "Symbolic Link"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ManagementTemplate>
  );
}
