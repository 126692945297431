import { Button, Stack, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconAvatar from "../atoms/IconAvatar";

interface EditButtonProps {
  editMode: boolean;
  onSave: () => any;
  onCancel: () => any;
  onEdit: () => any;
}

export default function EditButton(props: EditButtonProps) {
  const { editMode } = props;
  return (
    <Stack spacing={1} direction="row">    
    {editMode ? (
      <>
        <Button
          size="small"
          variant="outlined"
          color="success"
          onClick={props.onSave}
        >
          Save
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={props.onCancel}
        >
          Cancel
        </Button>
      </>
    ) : (
     
        <IconAvatar icon={EditOutlinedIcon} size={30} onClick={props.onEdit} tooltip="Edit"/>
      
    )}
  </Stack>
        
  );
}
