import {
  Box,
  ButtonBase,
  Divider,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useGlobalContext } from "../../contexts/Global";
import useBreakpoint from "../../hooks/useBreakpoint";
import SideBarButton from "../molecules/SideBarButton";
import SettingsIcon from "@mui/icons-material/Settings";
import StorageIcon from "@mui/icons-material/Storage";
import SecurityIcon from "@mui/icons-material/Security";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import Authorized from "../atoms/Authorized";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AvatarWithInitials from "../atoms/AvatarWithInitials";
import { useRef, useState } from "react";
import AccountPopover from "../molecules/AccountPopover";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import GradingIcon from "@mui/icons-material/Grading";

export const sideBarWidth = 260;

function SideBarContent() {
  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      <SideBarButton
        icon={<DashboardIcon />}
        value="Dashboard"
        page="dashboard"
      />

      <SideBarButton
        icon={<ConfirmationNumberIcon />}
        value="Tickets"
        page="ticket"
      />
      <SideBarButton icon={<StorageIcon />} value="Storage" page="storage" />
      <Authorized role="admin">
        <SideBarButton icon={<PersonIcon />} value="Users" page="user" />
        <SideBarButton
          icon={<GroupIcon />}
          value="Departments"
          page="department"
        />
      </Authorized>
      <Divider />
      <Authorized role="admin">
        <SideBarButton
          icon={<SecurityIcon />}
          value="Security"
          page="security"
        />
        <SideBarButton icon={<GradingIcon />} value="Logs" page="logs" />
        <SideBarButton
          icon={<SettingsIcon />}
          value="Settings"
          page="settings"
        />
      </Authorized>
    </Stack>
  );
}

export default function SideBar() {
  const { context, setContext } = useGlobalContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<any>();
  const breakpoint = useBreakpoint();
  return (
    <>
      <Box
        height={1}
        width={context.sidebar ? sideBarWidth : undefined}
        display="flex"
        flexDirection="column"
        sx={{
          transitionDuration: "200ms",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <Toolbar sx={{ height: 50 + 32 }} />
        <Paper
          sx={{
            position: "relative",
            bgcolor: "background.default",
            flexGrow: 1,
            width: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {breakpoint.md && (
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 30,
                width: 30,
                borderRadius: "50%",
                position: "absolute",
                top: -10,
                right: -10,
                bgcolor: "background.default",
                border: "1px solid var(--Color-Border, #E2E8F0)",
                zIndex: 9,
              }}
              onClick={() =>
                setContext({ ...context, sidebar: !context.sidebar })
              }
            >
              <KeyboardArrowRightIcon
                sx={{
                  transition: "transform 200ms ease-in-out",
                  transform: !context.sidebar
                    ? "rotate(0deg)"
                    : "rotate(180deg)",
                }}
              />
            </Paper>
          )}
          <Box flexGrow={1}>
            <SideBarContent />
          </Box>
          <Box>
            <Divider />
            <ButtonBase
              onClick={() => setOpen(true)}
              ref={anchorEl}
              sx={{
                p: 2,
                width: 1,
                justifyContent: context.sidebar ? "space-between" : undefined,
              }}
            >
              <Stack spacing={2} direction="row" alignItems="center">
                <AvatarWithInitials size={36}>
                  {context.user?.name || ""}
                </AvatarWithInitials>
                {context.sidebar && (
                  <Box textAlign="left">
                    <Typography fontSize={16} fontWeight="bold">
                      {context?.user?.username}
                    </Typography>
                    <Typography fontWeight="light" fontSize={15}>
                      {context?.user?.role}
                    </Typography>
                  </Box>
                )}
              </Stack>
              {context.sidebar && (
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 30, color: "text.secondary" }}
                />
              )}
            </ButtonBase>
          </Box>
        </Paper>
      </Box>
      <AccountPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ ml: 1 }}
      />
    </>
  );
}
