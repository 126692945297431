import { DropDownItem } from "../components/molecules/Dropdown";

export const locations: DropDownItem[] = [
  { name: "Office", value: "Office" },
  { name: "Lefkoşa", value: "Lefkoşa" },
  { name: "Girne", value: "Girne" },
  { name: "Gazimağusa", value: "Gazimağusa" },
  { name: "İskele", value: "İskele" },
  { name: "Güzelyurt", value: "Güzelyurt" },
  { name: "Lefke", value: "Lefke" },
];

export const priorities: DropDownItem[] = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
];

export const statuses: DropDownItem[] = [
  { name: "Not reviewed", value: "Not reviewed" },
  { name: "Incomplete", value: "Incomplete" },
  { name: "Pending confirmation", value: "Pending confirmation" },
  { name: "Complete", value: "Complete" },
];
