import { Dialog, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DropDownItem } from "../molecules/Dropdown";
import { FirewallService } from "../../services/api";
import { CreateRecordBodyTypeEnum } from "../../services/swagger";
import FormInput from "../molecules/FormInput";
import useRefresh from "../../hooks/useRefresh";
import FormTemplate from "../templates/FormTemplate";

interface CreateRecordDialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

const types: DropDownItem[] = [
  { name: "Blacklist", value: "blacklist" },
  { name: "Whitelist", value: "whitelist" },
];

export default function CreateRecordDialog(props: CreateRecordDialogProps) {
  const refresh = useRefresh();
  const [ip, setIP] = useState("");
  const [subnet, setSubnet] = useState("");
  const [type, setType] = useState<CreateRecordBodyTypeEnum>("blacklist");

  useEffect(() => {
    if (!props.open) return;
    setIP("");
    setSubnet("");    
    setType("blacklist");
  }, [props.open]);
  
  const handleSubmit = async () => {
    const res = await FirewallService.createRecord({
      createRecordBody: {
        ip,
        subnet,
        type,
      },
    });
    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title="Create Record"
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2}>
          <FormInput
            field="type"
            label="Type"
            onChange={(v) => {
              setType(v as CreateRecordBodyTypeEnum);
            }}
            type="list"
            required
            value={type}
            list={types}
          />
          <FormInput
            label="IP"
            value={ip}
            placeholder="ex. 192.168.0.1"
            required
            field="ip"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setIP(v);
            }}
          />
          <FormInput
            label="Subnet"
            field="subnet"
            type="string"
            value={subnet}
            placeholder="ex. 255.255.255.0"
            required
            onChange={(v) => {
              if (typeof v === "string") setSubnet(v);
            }}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
