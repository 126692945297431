import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  PopoverOrigin,
  PopoverVirtualElement,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router";
import { useGlobalContext } from "../../contexts/Global";

interface AccountPopoverProps {
  anchorEl?:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  open: boolean;
  onClose?: (closeSidebar?: boolean) => any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
}

export default function AccountPopover(props: AccountPopoverProps) {
  const { context, setContext } = useGlobalContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const match = useMatch("/account/:page/*");
  const isHome = useResolvedPath("").pathname === "/home";

  const page = match?.params.page;

  const getMenuItemColor = (targetPage?: string) => {
    if (targetPage === page) return theme.palette.primary.light;
    else return theme.palette.text.primary;
  };

  const navigateToPage = (targetPage: "info" | "forgot-password") => {
    navigate(`/account/${targetPage}`);
    props.onClose?.(true);
  };

  const logout = async () => {
    if (!context.user) return;
    localStorage.removeItem("authToken");
    setContext({ ...context, user: undefined });
    navigate("/home");
    props.onClose?.(true);
  };

  return (
    <Popover
      open={props.open}
      onClose={() => props.onClose?.()}
      anchorEl={props.anchorEl}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
      container={document.body}
      sx={{ ...props.sx, zIndex: 9999999 }}
    >
      <MenuList sx={{ minWidth: 150, position: "relative" }}>
        {!isHome && (
          <MenuItem
            onClick={() => {
              navigate("/home");
              props.onClose?.(true);
            }}
          >
            Home
          </MenuItem>
        )}
        <MenuItem
          onClick={() => navigateToPage("info")}
          sx={{ color: getMenuItemColor("info") }}
        >
          Account Info
        </MenuItem>
        <MenuItem
          onClick={() => navigateToPage("forgot-password")}
          sx={{ color: getMenuItemColor("forgot-password") }}
        >
          {context.user ? "Change Password" : "Forgot Password"}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Popover>
  );
}
