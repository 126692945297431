import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

export default function useBreakpoint() {
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.up("xs"));

  return {
    xl,
    lg,
    md,
    sm,
    xs,
  };
}
