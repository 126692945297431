import { Divider, Menu } from "@mui/material";
import TranslateButton from "../atoms/TranslateButton";
import AskAiButton from "../atoms/AskAiButton";
import SummarizeButton from "../atoms/SummarizeButton";

interface AiMenuProps {
  anchorEl: any;
  aiResponseMode: boolean;
  editMode: boolean;
  onClose: () => any;
  onTranslate: () => any;
  onSummarize: () => any;
  onAsk: () => any;
}

export default function AiMenu(props: AiMenuProps) {
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      PaperProps={{
        elevation: 2,
        sx: {
          width: 150,
          bgcolor: "background.default",
          overflow: "auto",
          mt: 1.5,
          padding: 1,
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      onClick={props.onClose}
    >
      <TranslateButton
        aiResponseMode={props.aiResponseMode}
        onTranslate={props.onTranslate}
      />
      <Divider sx={{ mt: 1, mb: 1 }} />

      <SummarizeButton
        aiResponseMode={props.aiResponseMode}
        onSummarize={props.onSummarize}
      />
      <Divider sx={{ mt: 1, mb: 1 }} />

      <AskAiButton
        aiResponseMode={props.aiResponseMode}
        onAskAi={props.onAsk}
      />
    </Menu>
  );
}
