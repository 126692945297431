import { Box, Button, Dialog, Divider, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import FormTemplate from "../templates/FormTemplate";
import NewFileIcon from "@mui/icons-material/NoteAdd";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { toast } from "react-toastify";
import { formatBytes } from "../pages/Storage";
import DeleteButton from "../atoms/DeleteButton";

interface UploadDialogProps {
  open: boolean;
  onCancel?: () => void;
  onSubmit?: (files: File[]) => any;
}

export default function UploadDialog(props: UploadDialogProps) {
  const fileRef = useRef<any>();
  const [files, setFiles] = useState<File[]>([]);

  const handleRemoveFile = (name: string) => {
    setFiles(files.filter((e) => e.name !== name));
  };

  const handleClickUpload = () => {
    fileRef.current.click();
  };

  const handleChangeFileSelection = () => {
    const arr = [...files];
    for (const file of fileRef.current.files) {
      if (arr.some((e) => e.name === file.name))
        toast(`${file.name} already exists on the list`, { type: "error" });
      else arr.push(file);
    }
    setFiles(arr);
    fileRef.current.value = "";
  };

  const handleClose = () => {
    setFiles([]);
    props.onCancel?.();
  };

  const handleSubmit = () => {
    props.onSubmit?.([...files]);
    handleClose();
  };

  return (
    <>
      <input
        ref={fileRef}
        name="files"
        type="file"
        style={{ display: "none" }}
        onChange={handleChangeFileSelection}
        multiple
      />
      <Dialog open={props.open} onClose={handleClose}>
        <FormTemplate
          noForm
          onCancel={handleClose}
          onSubmit={handleSubmit}
          title="Upload"
        >
          <Stack spacing={1}>
            {files.map((file) => (
              <Box
                sx={{
                  ":hover": {
                    bgcolor: (theme) => theme.palette.background.paper,
                  },
                }}
              >
                <Stack
                  mb={1}
                  mt={1}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <InsertDriveFileIcon
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                  <Box display="flex" alignItems="center" flexGrow={1}>
                    <Box pr={1} flexGrow={1}>
                      <Typography>{file.name}</Typography>
                      <Typography>{formatBytes(file.size)}</Typography>
                    </Box>
                    <Box pr={1}>
                      <DeleteButton
                        onClick={() => handleRemoveFile(file.name)}
                      />
                    </Box>
                  </Box>
                </Stack>
                <Divider />
              </Box>
            ))}
            <Button
              onClick={handleClickUpload}
              fullWidth
              startIcon={<NewFileIcon />}
            >
              Click to upload
            </Button>
          </Stack>
        </FormTemplate>
      </Dialog>
    </>
  );
}
