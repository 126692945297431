import { getBrowserId } from "../helpers/browser";
import { NotificationService } from "../services/api";
import * as worker from "./worker";

export async function stopServiceWorker() {
  if (!("serviceWorker" in navigator)) return;
  const registrations = await navigator.serviceWorker.getRegistrations();
  if (!registrations) return;
  for (const registration of registrations) {
    try {
      const subscription = await registration.pushManager.getSubscription();
      await subscription?.unsubscribe();
    } catch (err) {
      console.log(err);
    }
    await registration.unregister();
  }
  navigator.serviceWorker.removeEventListener(
    "message",
    serviceWorkerMessageListener
  );
}

export async function startServiceWorker() {
  const permission = await Notification.requestPermission();
  if (permission !== "granted") return;
  if (!("serviceWorker" in navigator)) return;
  const registrations = await navigator.serviceWorker.getRegistrations();
  if (registrations.length) return;

  await stopServiceWorker();

  navigator.serviceWorker.addEventListener(
    "message",
    serviceWorkerMessageListener
  );
  worker.register(`${window.location.origin}/js/service-worker.js`, {
    onSuccess: () => {
      console.log("Service Worker Registered.");
    },
    onUpdate: () => {
      console.log("Service Worker Updated.");
    },
  });
}

async function serviceWorkerMessageListener(
  this: ServiceWorkerContainer,
  e: MessageEvent<any>
) {
  const browserId = getBrowserId();
  const subscription = JSON.parse(e.data);
  if (subscription.notification) return;
  await NotificationService.createSubscription({
    createNotificationSubscriptionBody: {
      browserId,
      subscription,
    },
  });
}
