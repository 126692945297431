import QuizIcon from "@mui/icons-material/Quiz";
import IconAvatar from "./IconAvatar";

interface AskAiButtonProps {
  aiResponseMode?: boolean;
  onAskAi?: () => any;
}

export default function AskAiButton(props: AskAiButtonProps) {
  return (
    <IconAvatar
      icon={QuizIcon}
      size={30}
      onClick={props.onAskAi}
      tooltipTitle="Ask AI"
    />
  );
}
