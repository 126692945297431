import { Box, Container } from "@mui/material";
import MainTemplate from "../../templates/MainTemplate";
import CreateTicketPage from "./CreateTicket";

export default function CreateMyTicketPage() {
  return (
    <MainTemplate>
      <Box height={1} pt={4} pb={4} bgcolor="background.paper">
        <Container>
          <CreateTicketPage />
        </Container>
      </Box>
    </MainTemplate>
  );
}
