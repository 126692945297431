import {
  Avatar,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useMatch, useNavigate } from "react-router";
import { useRef, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import AvatarWithInitials from "../atoms/AvatarWithInitials";
import AccountPopover from "./AccountPopover";

export default function AccountIconButton() {
  const { context } = useGlobalContext();
  const anchorEl = useRef<any>();
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton ref={anchorEl} onClick={() => setOpen(true)} size="small">
        <AvatarWithInitials size={36}>
          {context.user?.name || ""}
        </AvatarWithInitials>
      </IconButton>
      <AccountPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );
}
