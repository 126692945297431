import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import IconAvatar from "./IconAvatar";

interface SummarizeButtonProps {
  aiResponseMode?: boolean;
  onSummarize?: () => any;
}

export default function SummarizeButton(props: SummarizeButtonProps) {
  return (
    <IconAvatar
      icon={TextSnippetIcon}
      size={30}
      onClick={props.onSummarize}
      tooltipTitle="Summarize"
    />
  );
}
