import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import FormInput from "../molecules/FormInput";
import { FormEvent, useState } from "react";
import { AuthService } from "../../services/api";
import { useGlobalContext } from "../../contexts/Global";
import { useNavigate } from "react-router";

interface OTPDialogProps {
  open: boolean;
  handleClose: (success: boolean) => void;
}

export default function OTPDialog(props: OTPDialogProps) {
  const { context, setContext } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    const res = await AuthService.otpLogin({
      otp,
      token: localStorage.getItem("authToken") || "",
    });
    setLoading(false);
    if (res?.data?.success) {
      props.handleClose(true);
      localStorage.setItem("authToken", res.data.data?.token || "");
      setContext({ ...context, user: res.data.data?.user });
      navigate("/");
    }
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          One-time password
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 400 }}>
        <Typography mb={4}>
          Please enter the one-time password from your authenticator app
        </Typography>
        <FormInput
          label="OTP Code"
          field="code"
          type="number"
          value={otp}
          onChange={(value) => setOtp(value as string)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          onClick={() => props.handleClose(false)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
