import { Box } from "@mui/material";
import NavBar from "../organisms/NavBar";
import Footer from "../organisms/Footer";

export default function MainTemplate({ children }: { children?: any }) {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* <ContactBar /> */}
      <NavBar />
      <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
        <Box flexGrow={1}>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}
