import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  IconProps,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { MouseEventHandler } from "react";

interface DeleteButtonProps {
  boxProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
  avatarProps?: AvatarProps;
  iconProps?: IconProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function DeleteButton(props: DeleteButtonProps) {
  return (
    <Tooltip title={"Delete"}>
      <Box {...props.boxProps}>
        <IconButton
          size="small"
          onClick={props.onClick}
          sx={{ p: 0 }}
          {...props.iconButtonProps}
        >
          <Avatar
            color="error"
            variant="rounded"
            sx={{
              bgcolor: (theme) => theme.palette.error.main,
              height: 30,
              width: 30,
            }}
            {...props.avatarProps}
          >
            <DeleteIcon
              sx={{
                ...props.iconProps?.sx,
                color: (theme) => theme.palette.error.contrastText,
              }}
            />
          </Avatar>
        </IconButton>
      </Box>
    </Tooltip>
  );
}
