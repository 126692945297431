import { Box } from "@mui/material";
import AvatarWithInitials, { stringToColor } from "../atoms/AvatarWithInitials";

interface AvatarBannerProps {
  size?: number;
  children: string;
}

export default function AvatarBanner(props: AvatarBannerProps) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        position="absolute"
        height={(props.size || 32) + 4}
        width={1}
        sx={{
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
        }}
        bgcolor={stringToColor(props.children)}
      ></Box>
      <Box
        sx={{
          pt: (props.size || 32) / 16,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          zIndex={2}
          height={(props.size || 32) + 8}
          width={(props.size || 32) + 8}
          borderRadius="50%"
          sx={{
            bgcolor: "background.default",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AvatarWithInitials size={props.size}>
            {props.children}
          </AvatarWithInitials>
        </Box>
      </Box>
    </Box>
  );
}
