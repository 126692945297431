import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { FtpService } from "../../services/api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormTemplate from "../templates/FormTemplate";
import NewFileIcon from "@mui/icons-material/NoteAdd";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { toast } from "react-toastify";
import { formatBytes } from "../pages/Storage";
import DeleteButton from "../atoms/DeleteButton";
import UploadDialog from "./UploadDialog";

interface UploadDialogProps extends ButtonProps {
  path: string;
  onSuccess?: () => any;
}

export default function UploadButton(props: UploadDialogProps) {
  const [open, setOpen] = useState(false);

  const handleUpload = async (files: File[]) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }
    const res = await FtpService.createFileUpload(
      {
        path: props.path,
      },
      {
        data: formData,
      }
    );
    if (res?.data?.success) {
      props.onSuccess?.();
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UploadDialog
        open={open}
        onCancel={handleClose}
        onSubmit={handleUpload}
      />
      <Button
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        onClick={handleOpen}
      >
        Upload
      </Button>
    </>
  );
}
