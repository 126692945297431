import { Button, ButtonBase, Chip, TableCell } from "@mui/material";
import ManagementTemplate from "../templates/ManagementTemplate";
import DataTable, { QueryParameters } from "../organisms/DataTable";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Log, PaginatedListLog } from "../../services/swagger";
import { LogService } from "../../services/api";

export default function LogsPage() {
  const navigate = useNavigate();
  const [logs, setLogs] = useState<PaginatedListLog>({
    count: 0,
    docs: [],
  });

  const loadLogs = async (query: QueryParameters) => {
    const res = await LogService.getLogList({
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (res?.data?.data) setLogs(res?.data?.data);
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadLogs(query);
  };

  const handleReferenceNavigation = (log: Log) => {
    if (!log?.object || !log?.model) return;
    navigate(`/m/${log.model}/${log.object}`);
  };

  return (
    <ManagementTemplate paper>
      <DataTable
        name="Logs"
        headers={[
          { field: "date", name: "Date", type: "date" },
          {
            field: "object",
            name: "Reference",
            type: "other",
            renderRowCell: (row: Log) => (
              <TableCell key={row._id} width={100}>
                <Button
                  size="small"
                  onClick={() => handleReferenceNavigation(row)}
                  variant="contained"
                  disabled={!row.object || !row.model}
                >
                  Ref
                </Button>
              </TableCell>
            ),
          },
          {
            field: "user",
            name: "User",
            type: "other",
            renderRowCell: (row: Log) => (
              <TableCell key={row._id}>
                {row.user?.username || row.username}
              </TableCell>
            ),
          },
          { field: "model", name: "Model", type: "string" },
          {
            field: "action",
            name: "Action",
            type: "other",
            renderRowCell: (row: Log) => (
              <TableCell key={row._id}>
                {row.action || `${row.object} ${(row as any).type}`}
              </TableCell>
            ),
          },
        ]}
        onChange={handleDataTableChange}
        content={logs}
        pagination
        queryFunction={LogService.getLogListQuery}
      />
    </ManagementTemplate>
  );
}
