import {
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";
import { DateRange } from "../../services/swagger";
import { Paper } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMemo, useState } from "react";

interface DateRangeSelectorProps {
  range: DateRange;
  rangeDisplacement: number;
  onChange?: (range: DateRange, rangeDisplacement: number) => void;
}

const dateRangeLabels = {
  week: "Week",
  month: "Month",
  year: "Year",
  all_time: "All Time",
};

export default function DateRangeSelector(props: DateRangeSelectorProps) {
  const { range, rangeDisplacement } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const breakpoint = useBreakpoint();

  const rangeStr = useMemo(() => {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    if (range === DateRange.AllTime) return "";
    if (range === DateRange.Week) {
      startDate.setDate(
        startDate.getDate() - startDate.getDay() - 7 * rangeDisplacement
      );
      endDate.setDate(
        endDate.getDate() - endDate.getDay() - 7 * (rangeDisplacement - 1)
      );
      const startDateStr = startDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const endDateStr = endDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `${startDateStr} - ${endDateStr}`;
    }
    if (range === DateRange.Month) {
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - rangeDisplacement);
      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() - rangeDisplacement + 1);
      const startDateStr = startDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const endDateStr = endDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `${startDateStr} - ${endDateStr}`;
    }
    if (range === DateRange.Year) {
      startDate.setDate(1);
      startDate.setMonth(0);
      startDate.setFullYear(startDate.getFullYear() - rangeDisplacement);
      endDate.setDate(1);
      endDate.setMonth(0);
      endDate.setFullYear(endDate.getFullYear() - rangeDisplacement + 1);
      const startDateStr = startDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const endDateStr = endDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `${startDateStr} - ${endDateStr}`;
    }
  }, [rangeDisplacement, range]);

  const handleDecrementDisplacement = () => {
    if (rangeDisplacement < 1) return;
    props.onChange?.(range, rangeDisplacement - 1);
  };

  const handleIncrementDisplacement = () => {
    props.onChange?.(range, rangeDisplacement + 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetRange = (newRange: DateRange) => {
    props.onChange?.(newRange, 0);
    handleClose();
  };

  return (
    <Box
      p={breakpoint.md ? undefined : 2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={breakpoint.md ? "row" : "column-reverse"}
    >
      <Box width={!breakpoint.md ? 1 : undefined}>
        {range !== DateRange.AllTime && (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              sx={{ borderRadius: 1, color: "primary.main" }}
              onClick={handleIncrementDisplacement}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Paper
              sx={{
                p: 2,
                bgcolor: "background.default",
                borderRadius: 8,
                minWidth: 250,
                flexGrow: !breakpoint.md ? 1 : undefined,
              }}
            >
              <Typography color="primary" align="center" fontWeight={600}>
                {rangeStr}
              </Typography>
            </Paper>
            <IconButton
              sx={{ borderRadius: 1, color: "primary.main" }}
              onClick={handleDecrementDisplacement}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Stack>
        )}
      </Box>
      <Paper
        onClick={(e) => setAnchorEl(e.currentTarget)}
        component={ButtonBase}
        sx={{
          borderRadius: 8,
          bgcolor: "background.default",
          p: 2,
          cursor: "pointer",
          mb: { xs: 2, md: 0 },
          width: { xs: 1, md: "fit-content" },
        }}
      >
        <Stack
          width={!breakpoint.md ? 1 : undefined}
          spacing={6}
          direction="row"
          alignItems="center"
          justifyContent={!breakpoint.md ? "space-between" : undefined}
        >
          <Typography color="primary" fontWeight={600}>
            {dateRangeLabels[range]}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              color: "primary.main",
              transition: "transform 100ms ease-in-out",
              transform: Boolean(anchorEl) ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Stack>
      </Paper>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: breakpoint.md ? "right" : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: breakpoint.md ? "right" : "center",
        }}
        anchorEl={anchorEl}
      >
        <MenuList sx={{ width: { md: 150, sm: 450, xs: 300 } }}>
          <MenuItem
            onClick={() => handleSetRange(DateRange.Week)}
            sx={{
              color: range === DateRange.Week ? "primary.main" : undefined,
            }}
          >
            {dateRangeLabels[DateRange.Week].toUpperCase()}
          </MenuItem>
          <MenuItem
            onClick={() => handleSetRange(DateRange.Month)}
            sx={{
              color: range === DateRange.Month ? "primary.main" : undefined,
            }}
          >
            {dateRangeLabels[DateRange.Month].toUpperCase()}
          </MenuItem>
          <MenuItem
            onClick={() => handleSetRange(DateRange.Year)}
            sx={{
              color: range === DateRange.Year ? "primary.main" : undefined,
            }}
          >
            {dateRangeLabels[DateRange.Year].toUpperCase()}
          </MenuItem>
          <MenuItem
            onClick={() => handleSetRange(DateRange.AllTime)}
            sx={{
              color: range === DateRange.AllTime ? "primary.main" : undefined,
            }}
          >
            {dateRangeLabels[DateRange.AllTime].toUpperCase()}
          </MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
}
