import { Box, Grid, Stack, Typography } from "@mui/material";
import OfficeMap from "../atoms/OfficeMap";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FollowUs from "../molecules/FollowUs";
import appConfig from "../../config/app-config";

export default function Footer() {
  return (
    <Grid
      container
      sx={{
        background: "#343434F2",
        bottom: 0,
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          WHAT WE DO
        </Typography>
        <Typography variant="body2" fontWeight={200}>
          Apart from project consultancy, installation and post-installation
          services, we also provide TDM/IP PBX and Call Center solutions, fiber
          optic cabling, termination and testing services, and sales and
          after-sales support of network and transmission equipment.
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          CONTACT
        </Typography>
        <Stack direction={"column"} spacing={1}>
          <Stack direction={"row"}>
            <EmailIcon sx={{ mr: 2 }} />
            <Typography flexGrow={1} fontWeight={200}>
              {appConfig.contact.email}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <PhoneIcon sx={{ mr: 2 }} />
            <Typography flexGrow={1} fontWeight={200}>
              {appConfig.contact.phone}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <PlaceIcon sx={{ mr: 2 }} />
            <Typography maxWidth={180} fontWeight={200}>
              {appConfig.contact.address}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <Typography gutterBottom variant="h6">
          FOLLOW US
        </Typography>
        <Box display="flex">
          <FollowUs />
        </Box>
      </Grid>
      <Grid item xs={12} md={3} p={4}>
        <OfficeMap />
      </Grid>
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          background: "#343434",
          p: 2,
          color: (theme) => theme.palette.primary.contrastText,
        }}
      >
        <Stack direction={"row"} justifyContent={"center"} spacing={1}>
          <Typography sx={{ fontWeight: 300 }}>
            Copyright © {new Date().getFullYear()}
          </Typography>
          <Typography
            component="a"
            sx={{
              textDecoration: "none",
              color: (theme) => theme.palette.primary.light,
              fontWeight: 300,
            }}
            target="_blank"
            href={appConfig.links.website.link}
          >
            {appConfig.links.website.label}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
