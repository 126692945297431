import { useMatch, useNavigate } from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";
import SideBarButtonBase from "./SideBarButtonBase";

interface SideBarButtonProps {
  icon?: any;
  value: string;
  page: string;
}

export default function SideBarButton(props: SideBarButtonProps) {
  const { context, setContext } = useGlobalContext();
  const match = useMatch("/m/:page/*");
  const isPageMatched = match?.params.page === props.page;
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const handleClick = () => {
    if (!breakpoint.md) setContext({ ...context, sidebar: false });
    navigate(`/m/${props.page}`);
  };

  return (
    <SideBarButtonBase
      matched={isPageMatched}
      value={props.value}
      onClick={handleClick}
      icon={props.icon}
    />
  );
}
