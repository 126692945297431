import { generateV1UUID } from "./uuid";

export function getBrowserId(): string {
  const browserId = localStorage.getItem("browserId");
  if (!browserId) {
    const newBrowserId = generateV1UUID();
    localStorage.setItem("browserId", newBrowserId);
    return newBrowserId;
  }
  return browserId;
}
