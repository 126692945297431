import { useNavigate, useParams } from "react-router";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { Stack } from "@mui/material";
import { Department, User } from "../../../services/swagger";
import { useEffect, useState } from "react";
import { DepartmentService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import useConfirm from "../../../hooks/useConfirm";

export default function EditDepartmentPage() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [administrative, setAdministrative] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [department, setDepartment] = useState<Department>();

  const loadDepartment = async () => {
    const res = await DepartmentService.getDepartment({
      id: _id || "",
    });
    if (res?.data?.data) setDepartment(res.data.data);
  };

  const refresh = useRefresh(loadDepartment);

  useEffect(() => {
    if (!department) return;
    setName(department.name);
    setDescription(department.description);
    setUsers(department.users);
    setAdministrative(department.administrative);
  }, [department]);

  useEffect(() => {
    loadDepartment();
  }, []);

  const handleCancel = () => {
    navigate("/m/department");
  };

  const handleSubmit = async () => {
    if (!_id) return;
    const res = await DepartmentService.updateDepartment({
      id: _id,
      updateDepartmentBody: {
        name,
        description,
        users: users.map((user) => user._id || ""),
        administrative,
      },
    });
    if (res?.data?.success) navigate("/m/department");
  };

  const { confirm: confirmSubmit, ConfirmDialog } = useConfirm();

  return (
    <ManagementTemplate paper>
      <ConfirmDialog />
      <FormTemplate
        title={`Edit ${department?.name}`}
        onSubmit={() => confirmSubmit(handleSubmit)}
        onCancel={handleCancel}
      >
        <Stack spacing={2}>
          <FormInput
            field="name"
            label="Name"
            onChange={(v) => {
              setName(v as string);
            }}
            type="string"
            value={name}
            required
          />
          <FormInput
            field="description"
            label="Description"
            onChange={(v) => {
              setDescription(v as string);
            }}
            type="string"
            value={description}
          />
          <FormInput
            field="administrative"
            label="Administrative"
            value={administrative}
            type="boolean"
            onChange={(v) => setAdministrative(v as boolean)}
            required
          />
        </Stack>
      </FormTemplate>
    </ManagementTemplate>
  );
}
