import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OpenAiService } from "../../services/api";
import { useState } from "react";

interface AskAiDialogProps {
  openDialog: boolean;
  isDialogLoading: boolean;
  onClose: () => any;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  content: string;

}

export default function AskAiDialog(props: AskAiDialogProps) {
      
  return (
    <>
      <Dialog
        open={props.openDialog}
        onClose={props.onClose}
        PaperProps={{
            component: "form",
            onSubmit: props.onSubmit,
          }}
      >
        <DialogTitle>Ask AI</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            You can edit the content to remove any private information before
            confirming.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="aiprompt"
            name="aiprompt"
            label="Prompt"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            defaultValue={props.content}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="submit">Ask AI</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
