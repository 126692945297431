import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Ticket } from "../../../services/swagger";
import CreateServiceCallDialog from "./CreateServiceCallDialog";
import { useEffect, useState } from "react";
import { SapService } from "../../../services/api";
import FormInput from "../../molecules/FormInput";
import Add from "@mui/icons-material/Add";
import useRefresh from "../../../hooks/useRefresh";
import TicketOldServiceReportDialog from "./TicketOldServiceReportDialog";
import AttachServiceCallDialog from "./AttachServiceCallDialog";

interface TicketServiceCall {
  ticket: Ticket;
}

export default function TicketServiceCall(props: TicketServiceCall) {
  const { ticket } = props;
  const [createOpen, setCreateOpen] = useState(false);
  const [attachOpen, setAttachOpen] = useState(false);
  const [contact, setContact] = useState<any>();

  const loadContact = async () => {
    if (!ticket.service_call?.contact_no) return;
    const res = await SapService.getContact({
      contactNo: ticket.service_call?.contact_no,
    });

    if (
      res?.data?.data &&
      Array.isArray(res.data.data) &&
      res.data.data.length === 1
    ) {
      const newContact = res.data.data[0];
      setContact(newContact);
    }
  };

  useRefresh(loadContact);

  useEffect(() => {
    loadContact();
  }, [props.ticket]);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <CreateServiceCallDialog
        open={createOpen}
        ticket={ticket}
        onClose={() => setCreateOpen(false)}
      />
      <AttachServiceCallDialog
        open={attachOpen}
        ticket={ticket}
        onClose={() => setAttachOpen(false)}
      />
      <Stack spacing={2}>
        <Box>
          <Typography fontWeight={600}>Service Call</Typography>
          <Divider />
        </Box>
        {!ticket.service_call && (
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setCreateOpen(true)}
                  startIcon={<Add />}
                >
                  Create
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setAttachOpen(true)}
                  startIcon={<Add />}
                >
                  Attach
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {ticket.service_call && contact && (
          <>
            <FormInput
              label={`Sap Contact (${contact.CardCode})`}
              field="Name"
              type="string"
              value={contact.Name}
              readOnly
            />
            {contact.Cellolar && (
              <FormInput
                label={`Cellular`}
                field="Cellolar"
                type="string"
                value={contact.Cellolar}
                readOnly
              />
            )}
            {contact.Tel1 && (
              <FormInput
                label={`Tel 1`}
                field="Tel1"
                type="string"
                value={contact.Tel1}
                readOnly
              />
            )}
            {contact.Tel2 && (
              <FormInput
                label={`Tel 2`}
                field="Tel2"
                type="string"
                value={contact.Tel2}
                readOnly
              />
            )}
            {contact.E_MailL && (
              <FormInput
                label={`Email`}
                field="E_MailL"
                type="string"
                value={contact.E_MailL}
                readOnly
              />
            )}
          </>
        )}
        {((ticket.service_call?.expenses?.length || 0) > 0 ||
          (ticket.service_call?.times?.length || 0) > 0) && (
          <>
            {ticket.service_call && (
              <TicketOldServiceReportDialog serviceCall={ticket.service_call} />
            )}
          </>
        )}
      </Stack>
    </Paper>
  );
}
