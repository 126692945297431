import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useEffect, useState } from "react";

interface QRDialogProps {
  srcFunction: () => Promise<string | undefined>;
  buttonProps?: ButtonProps;
  disabled?: boolean;
}

export default function QRButton(props: QRDialogProps) {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const loadQR = async () => {
    const data = await props.srcFunction();
    if (typeof data === "string") setSrc(data);
  };

  useEffect(() => {
    if (open) loadQR();
    else setSrc("");
  }, [open]);

  return (
    <>
      <Button
        disabled={props.disabled}
        onClick={() => setOpen(true)}
        {...props.buttonProps}
      >
        <QrCodeScannerIcon />
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Typography variant="h4" gutterBottom>
            QR
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please scan the QR code in your authenticator application
          </DialogContentText>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={4}
          >
            {src ? <img src={src} /> : <CircularProgress />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
