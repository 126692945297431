import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import FormInput from "../molecules/FormInput";
import { FormEvent, useState } from "react";
import { UserService } from "../../services/api";

interface ActivateAccountDialogProps {
  open: boolean;
  handleClose: (success: boolean) => void;
}

export default function ActivateAccountDialog(
  props: ActivateAccountDialogProps
) {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    const res = await UserService.updateUserActivation({
      code,
    });
    setLoading(false);
    if (res?.data?.success) props.handleClose(true);
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Activate your account
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        <Typography mb={4}>
          Please enter the code that was sent to your email
        </Typography>
        <FormInput
          label="Activation Code"
          field="code"
          type="string"
          value={code}
          onChange={(value) => setCode(value as string)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          onClick={() => props.handleClose(false)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Activate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
