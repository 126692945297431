import { Avatar, ButtonBase, SvgIconTypeMap, Tooltip, Typography, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { MouseEventHandler } from "react";

interface IconAvatarProps {
  ref?: any;
  variant?: "rounded" | "circular" | "square";
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  size?: "small" | "medium" | "large" | string | number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  tooltipTitle?:string;
}

export default function IconAvatar(props: IconAvatarProps) {
  const theme = useTheme();
  const sizes: any = {
    small: theme.typography.fontSize * 2,
    medium: theme.typography.fontSize * 3,
    large: theme.typography.fontSize * 4,
  };
  let size: number = sizes.medium;
  if (typeof props.size === "string") {
    size = sizes[props.size || "medium"];
  } else if (typeof props.size === "number") {
    size = props.size;
  }

  return (
    <Tooltip title={props.tooltip}>

    <ButtonBase onClick={props.onClick}>
      <Avatar
        variant={props.variant}
        sx={{
          bgcolor: (theme) => theme.palette.primary.light,
          width: size,
          height: size,
          mr: 1,
        }}
      >
        <props.icon
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
            fontSize: size * 0.7,
          }}
        />
      </Avatar>
      <Typography>{props.tooltipTitle}</Typography>
    </ButtonBase>
    </Tooltip>
  );
}