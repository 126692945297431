import { Box, Typography } from "@mui/material";
import appConfig from "../../config/app-config";

export default function TitleWithLogo() {
  return (
    <Box display="flex" alignItems="center">
      <img
        src={require("../../assets/logo.png")}
        style={{ height: 50, width: 50, marginRight: "1em" }}
      />
      <Typography fontWeight={500} fontSize={22}>
        {appConfig.nickname}
      </Typography>
    </Box>
  );
}
