import { createContext, useReducer, useContext } from "react";
import { AppSettings, User } from "../services/swagger";
import { Socket } from "socket.io-client";

interface GlobalContext {
  user: User | undefined;
  sidebar: boolean;
  refresh: boolean;
  settings?: AppSettings;
  socket?: Socket;
}

export const globalObject: GlobalContext = {
  user: undefined,
  sidebar: !window.matchMedia("(max-width: 768px)").matches,
  refresh: false,
};

export const globalContext = createContext(globalObject);
export const dispatchContext = createContext<any>(undefined);

export default function AuthStateProvider({ children }: { children: any }) {
  const [state, dispatch] = useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    globalObject
  );
  return (
    <globalContext.Provider value={state}>
      <dispatchContext.Provider value={dispatch}>
        {children}
      </dispatchContext.Provider>
    </globalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return {
    context: useContext(globalContext),
    setContext: useContext<(newState: GlobalContext) => void>(dispatchContext),
  };
};
