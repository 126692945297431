import { Box, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import TicketBody, {
  TicketBodyFields,
} from "../../organisms/Ticket/TicketBody";
import TicketSide, {
  TicketSideFields,
} from "../../organisms/Ticket/TicketSide";
import TicketAttachments from "../../organisms/Ticket/TicketAttachments";
import { FormEvent, useRef } from "react";
import { TicketService } from "../../../services/api";
import { TicketPriorityEnum } from "../../../services/swagger";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateTicketPage() {
  const navigate = useNavigate();
  const title = useRef("");
  const company = useRef("");
  const details = useRef("");
  const location = useRef("");
  const departmentId = useRef("");
  const attachments = useRef<File[]>([]);
  const priority = useRef<TicketPriorityEnum>("medium");
  const voucher = useRef("");

  const handleBodyChange = (fields: TicketBodyFields) => {
    title.current = fields.title;
    details.current = fields.details;
  };

  const handleAttachmentsChange = (files: File[]) => {
    attachments.current = files;
  };

  const handleSideChange = (fields: TicketSideFields) => {
    company.current = fields.company;
    priority.current = fields.priority;
    voucher.current = fields.voucher;
    location.current = fields.location;
    departmentId.current = fields.departmentId;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    for (const file of attachments.current) {
      formData.append("files", file);
    }
    const res = await TicketService.createTicket(
      {
        title: title.current,
        details: details.current,
        company: company.current,
        location: location.current,
        priority: priority.current,
        voucherId: voucher.current,
        departmentId: departmentId.current,
      },
      { data: formData }
    );
    if (res?.data?.success) navigate("../");
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.default",
            }}
          >
            <Typography fontWeight={600}>Create Ticket</Typography>
            <IconButton onClick={() => navigate(-1)}>
              <CloseIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            <TicketBody onChange={handleBodyChange} />
            <TicketAttachments onChange={handleAttachmentsChange} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <TicketSide onChange={handleSideChange} />
        </Grid>
      </Grid>
    </Box>
  );
}
