import { useNavigate, useResolvedPath } from "react-router";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useGlobalContext } from "../../contexts/Global";
import SideBarButtonBase from "./SideBarButtonBase";

interface MainSideBarButtonProps {
  icon?: any;
  value: string;
  path: string;
}

export default function MainSideBarButton(props: MainSideBarButtonProps) {
  const { context, setContext } = useGlobalContext();
  const { pathname } = useResolvedPath("");
  const isMatched = pathname === props.path;
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const handleClick = () => {
    if (!breakpoint.md) setContext({ ...context, sidebar: false });
    navigate(props.path);
  };

  return (
    <SideBarButtonBase
      matched={isMatched}
      value={props.value}
      onClick={handleClick}
      icon={props.icon}
    />
  );
}
