import { useEffect, useRef, useState } from "react";
import { getDownloadSrc } from "../helpers/download";

export default function useDownload() {
  const downloadRef = useRef<any>();
  const [path, setPath] = useState("");
  const [file, setFile] = useState("");
  const [ticketId, setTicketId] = useState("");

  const handleDownload = (e: any) => {
    e.stopPropagation();
  };

  const download = (filepath: string, ticketId?: string) => {
    if (ticketId) setTicketId(ticketId);
    setPath(filepath);
    setFile(filepath.substring(filepath.lastIndexOf("/"), filepath.length));
  };

  useEffect(() => {
    if (!path || !file) return;
    if (!downloadRef?.current?.click) return;
    if (file !== path.substring(path.lastIndexOf("/"), path.length)) return;
    downloadRef?.current?.click();
    setPath("");
    setFile("");
    setTicketId("");
  }, [path, file]);

  const DownloadLink = () => {
    return (
      <a
        onClick={handleDownload}
        href={getDownloadSrc(path, ticketId)}
        download={file}
        ref={downloadRef}
        style={{ display: "none" }}
      ></a>
    );
  };

  return { download, DownloadLink };
}
