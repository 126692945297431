import { Stack, Typography } from "@mui/material";
import appConfig from "../../config/app-config";

export default function FollowUs() {
  return (
    <Stack spacing={1} direction="column">
      {appConfig.links.facebook.label && (
        <Stack
          component="a"
          sx={{
            textDecoration: "none",
          }}
          target="_blank"
          href={appConfig.links.facebook.link}
          direction={"row"}
          spacing={1}
        >
          <img
            src={require("../../assets/facebook-new.png")}
            style={{ height: 20, width: 20 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 300,
            }}
          >
            {appConfig.links.facebook.label}
          </Typography>
        </Stack>
      )}

      {appConfig.links.linkedin.label && (
        <Stack
          component="a"
          sx={{
            textDecoration: "none",
          }}
          target="_blank"
          href={appConfig.links.linkedin.link}
          direction={"row"}
          spacing={1}
        >
          <img
            src={require("../../assets/linkedin.png")}
            style={{ height: 20, width: 20 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 300,
            }}
          >
            {appConfig.links.linkedin.label}
          </Typography>
        </Stack>
      )}

      {appConfig.links.x.label && (
        <Stack
          component="a"
          sx={{
            textDecoration: "none",
          }}
          target="_blank"
          href={appConfig.links.x.link}
          direction={"row"}
          spacing={1}
        >
          <img
            src={require("../../assets/twitterx.png")}
            style={{ height: 20, width: 20 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 300,
            }}
          >
            {appConfig.links.x.label}
          </Typography>
        </Stack>
      )}

      {appConfig.links.instagram.label && (
        <Stack
          component="a"
          sx={{
            textDecoration: "none",
          }}
          target="_blank"
          href={appConfig.links.instagram.link}
          direction={"row"}
          spacing={1}
        >
          <img
            src={require("../../assets/instagram.png")}
            style={{ height: 20, width: 20 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 300,
            }}
          >
            {appConfig.links.instagram.label}
          </Typography>
        </Stack>
      )}

      {appConfig.links.youtube.label && (
        <Stack
          component="a"
          sx={{
            textDecoration: "none",
          }}
          target="_blank"
          href={appConfig.links.youtube.link}
          direction={"row"}
          spacing={1}
        >
          <img
            src={require("../../assets/youtube.png")}
            style={{ height: 20, width: 20 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 300,
            }}
          >
            {appConfig.links.youtube.label}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
