import { useNavigate, useParams } from "react-router";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Filter, User, UserRole } from "../../../services/swagger";
import { useEffect, useState } from "react";
import { UserService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { roles } from "../../organisms/CreateUserDialog";
import useConfirm from "../../../hooks/useConfirm";
import QRButton from "../../organisms/QRButton";

export default function EditUserPage() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState<UserRole>("user");
  const [user, setUser] = useState<User | null>();
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);

  const loadUser = async () => {
    const filters: Filter[] = [
      { field: "_id", operator: "eq", type: "id", value: _id },
    ];
    const res = await UserService.getUserList({
      limit: 1,
      skip: 0,
      filters: JSON.stringify(filters),
    });
    if (res?.data?.data?.docs?.length === 1) setUser(res.data.data.docs[0]);
  };

  const refresh = useRefresh(loadUser);

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setCompany(user.company);
    setPhone(user.phone);
    setRole(user.role);
    setEmailConfirmation(user.emailConfirmation);
    setOtpEnabled(user.otpEnabled);
  }, [user]);

  useEffect(() => {
    if (role !== "user") setOtpEnabled(true);
  }, [role]);

  useEffect(() => {
    loadUser();
  }, []);

  const handleCancel = () => {
    navigate("/m/user");
  };

  const handleSubmit = async () => {
    if (!_id) return;
    const res = await UserService.updateUser({
      id: _id,
      updateUserBody: {
        name,
        company,
        phone,
        password: newPassword,
        role,
        otpEnabled,
        emailConfirmation,
      },
    });
    if (res?.data?.success) navigate("/m/user");
  };

  const handleLoadQr = async () => {
    if (!_id) return undefined;
    const res = await UserService.getUserQR({ id: _id });
    return res?.data?.data;
  };

  const { confirm: confirmSubmit, ConfirmDialog } = useConfirm();

  return (
    <ManagementTemplate paper>
      <ConfirmDialog />
      <FormTemplate
        title={`Edit ${user?.name}`}
        onSubmit={() => confirmSubmit(handleSubmit)}
        onCancel={handleCancel}
      >
        <Stack spacing={2}>
          <FormInput
            field="role"
            label="Role"
            onChange={(v) => {
              setRole(v as UserRole);
            }}
            type="list"
            required
            value={role}
            list={roles}
          />
          <FormInput
            field="email"
            label="Email"
            onChange={() => {}}
            placeholder="ex. john.doe@email.com"
            type="string"
            inputType="email"
            readOnly
            value={user?.email}
            required
          />
          <FormInput
            field="username"
            label="Username"
            readOnly
            value={user?.username}
            placeholder="Username"
            required
            type="string"
            onChange={() => {}}
          />
          <FormInput
            field="name"
            label="Full Name"
            value={name}
            placeholder="Full Name"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setName(v);
            }}
          />
          <FormInput
            field="company"
            label="Company"
            value={company}
            placeholder="Company"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setCompany(v);
            }}
          />
          <FormInput
            field="phone"
            label="Phone"
            value={phone}
            placeholder="Phone"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPhone(v);
            }}
          />
          <FormInput
            field="password"
            label="New Password"
            value={newPassword}
            placeholder="New Password"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setNewPassword(v);
            }}
          />
          <FormInput
            field="emailConfirmation"
            value={emailConfirmation}
            onChange={(x) => setEmailConfirmation(x as boolean)}
            label="Email Confirmation (MFA)"
            type="boolean"
            required
          />
          <Stack direction="row" spacing={1} alignItems="flex-end">
            <FormInput
              field="otpEnabled"
              value={otpEnabled}
              onChange={(x) => setOtpEnabled(x as boolean)}
              label="OTP (MFA)"
              type="boolean"
              disabled={user?.role !== "user"}
              required
            />
            <QRButton
              buttonProps={{ sx: { height: 55 } }}
              srcFunction={handleLoadQr}
              disabled={!otpEnabled}
            />
          </Stack>
        </Stack>
      </FormTemplate>
    </ManagementTemplate>
  );
}
