import { Box, Typography, useTheme } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";

interface HomeStepProps {
  stepNo: number;
  children: string;
  align: "left" | "right";
}

export default function HomeStep(props: HomeStepProps) {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const borderRadius = breakpoint.md
    ? theme.shape.borderRadius
    : theme.shape.borderRadius / 2;
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius={borderRadius}
      bgcolor="background.paper"
      width={1}
      sx={{ height: { xs: 120, md: 90 } }}
      flexDirection={props.align === "left" ? undefined : "row-reverse"}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="background.default"
        borderRadius={borderRadius}
        sx={{
          borderTopRightRadius:
            props.align === "left" ? borderRadius : undefined,
          borderTopLeftRadius:
            props.align === "right" ? borderRadius : undefined,
          width: { xs: 110, md: 220 },
          minWidth: { xs: 110, md: 220 },
        }}
        height={1}
        bgcolor={theme.palette.primary.main}
      >
        <Typography sx={{ fontSize: { xs: 20, md: 32 } }}>
          STEP {props.stepNo}
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Typography
          fontWeight="light"
          align="center"
          sx={{ fontSize: { xs: 12, sm: 14 } }}
        >
          {props.children}
        </Typography>
      </Box>
    </Box>
  );
}
