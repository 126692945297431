import { Container } from "@mui/material";
import ManagementTemplate from "../../templates/ManagementTemplate";
import CreateTicketPage from "./CreateTicket";

export default function CreateManagementTicketPage() {
  return (
    <ManagementTemplate>
      <Container
        sx={{
          pt: 2,
          pb: 2,
          boxSizing: "border-box",
        }}
      >
        <CreateTicketPage />
      </Container>
    </ManagementTemplate>
  );
}
