import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Department, TicketPriorityEnum } from "../../../services/swagger";
import { useGlobalContext } from "../../../contexts/Global";
import AvatarBanner from "../../molecules/AvatarBanner";
import { DepartmentService } from "../../../services/api";
import { locations, priorities } from "../../../helpers/objects";

export interface TicketSideFields {
  company: string;
  location: string;
  voucher: string;
  priority: TicketPriorityEnum;
  departmentId: string;
}

interface TicketSideProps {
  onChange: (fields: TicketSideFields) => void;
}

export default function TicketSide(props: TicketSideProps) {
  const { context } = useGlobalContext();
  const [company, setCompany] = useState("");
  const [voucher, setVoucher] = useState("");
  const [location, setLocation] = useState(locations[0].value as string);
  const [priority, setPriority] = useState<TicketPriorityEnum>("medium");
  const [departmentId, setDepartmentId] = useState("");
  const [departments, setDepartments] = useState<Department[]>([]);

  const loadDepartments = async () => {
    const res = await DepartmentService.getDepartmentMiniList();
    if (!res?.data?.data) return;
    setDepartments(res.data.data);
    if (res.data.data.length > 0) setDepartmentId(res.data.data[0]._id || "");
  };

  useEffect(() => {
    props.onChange({
      company,
      location,
      voucher,
      priority,
      departmentId,
    });
  }, [company, voucher, priority, location, departmentId]);

  useEffect(() => {
    loadDepartments();
  }, []);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={2}>
        <Box>
          <AvatarBanner size={72}>{context.user?.name || ""}</AvatarBanner>
          <Typography fontSize="large" textAlign="center" fontWeight={600}>
            {context.user?.name}
          </Typography>
          <Typography align="center" color="text.secondary">
            {context.user?.company}
          </Typography>
        </Box>
        <Divider />
        <FormInput
          role="agent"
          field="company"
          label="Company"
          type="string"
          placeholder="Your company"
          onChange={(v) => setCompany(v as string)}
          value={company}
          required
        />
        <FormInput
          field="priority"
          label="Priority"
          onChange={(v) => setPriority(v as TicketPriorityEnum)}
          type="list"
          value={priority}
          list={priorities}
          required
        />
        <FormInput
          field="location"
          label="Location"
          type="list"
          placeholder="Choose a location"
          onChange={(v) => setLocation(v as string)}
          value={location}
          list={locations}
          required
        />
        <FormInput
          field="departmentId"
          label="Department"
          type="list"
          placeholder="Choose a department"
          list={departments.map((department) => {
            return {
              name: department.name,
              value: department._id || "",
            };
          })}
          onChange={(v) => setDepartmentId(v as string)}
          value={departmentId}
          required
        />
        <FormInput
          field="voucher"
          label="Voucher"
          onChange={(v) => setVoucher(v as TicketPriorityEnum)}
          type="string"
          value={voucher}
          placeholder="XXXX-XXXX-XXXX-XXXX"
        />
        <Button variant="contained" color="success" type="submit">
          Create Ticket
        </Button>
      </Stack>
    </Paper>
  );
}
