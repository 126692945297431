import useRole from "../../hooks/useRole";
import { UserRole } from "../../services/swagger";

interface AuthorizedProps {
  role: UserRole;
  children: any;
}

export default function Authorized(props: AuthorizedProps) {
  const role = useRole();
  if (role(props.role)) return props.children;
  else return null;
}
