import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SapService } from "../../../services/api";
import FormInput from "../../molecules/FormInput";
import useRefresh from "../../../hooks/useRefresh";
import FormTemplate from "../../templates/FormTemplate";
import { Ticket } from "../../../services/swagger";
import useConfirm from "../../../hooks/useConfirm";

interface AttachServiceCallDialogProps {
  open: boolean;
  ticket: Ticket;
  onSubmit?: () => void;
  onClose?: () => void;
}

export default function AttachServiceCallDialog(
  props: AttachServiceCallDialogProps
) {
  const { ticket } = props;
  const [search, setSearch] = useState("");
  const [serviceCalls, setServiceCalls] = useState<any[]>([]);

  const { confirm, ConfirmDialog } = useConfirm();

  const refresh = useRefresh();

  const loadServiceCalls = async () => {
    const res = await SapService.getServiceCalls();
    if (Array.isArray(res?.data?.data))
      setServiceCalls(
        res.data.data.filter((row) => {
          return Boolean(
            `${row.callID} ${row.subject}`.match(
              new RegExp(search.replace(/i/g, "[iİ]"), "i")
            )
          );
        })
      );
  };

  useEffect(() => {
    if (!props.open) return;
    loadServiceCalls();
  }, [props.open]);

  const handleSubmit = async (callNo: number) => {
    if (!ticket._id) return;
    const res = await SapService.updateTicketAttachServiceCall({
      ticketId: ticket._id,
      callNo,
    });

    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ sx: { minWidth: 600 } }}
    >
      <ConfirmDialog />
      <FormTemplate
        title="Attach Service Call"
        disableSubmitButton
        onCancel={props.onClose}
      >
        <Stack spacing={2}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <FormInput
                  label="Search"
                  value={search}
                  placeholder="Search..."
                  field="search"
                  type="string"
                  onEnter={loadServiceCalls}
                  onChange={(v) => setSearch(v as string)}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems="flex-end" mb={1 / 4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={loadServiceCalls}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Paper
            elevation={4}
            sx={{
              height: 300,
              overflow: "auto",
              bgcolor: "background.default",
            }}
          >
            {serviceCalls.map((e) => {
              return (
                <Box>
                  <ButtonBase
                    onClick={() => confirm(() => handleSubmit(e.callID))}
                    sx={{
                      width: 1,
                      display: "block",
                      textAlign: "left",
                      p: 1,
                      pb: 0,
                    }}
                  >
                    <Typography fontWeight="bold" pl={1} pr={1}>
                      # {e.callID}
                    </Typography>
                    <Typography pl={1} pr={1}>
                      {e.subject}
                    </Typography>
                  </ButtonBase>
                  <Divider />
                </Box>
              );
            })}
          </Paper>
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
