import { Button, Paper, TableCell } from "@mui/material";
import ManagementTemplate from "../templates/ManagementTemplate";
import DataTable, { QueryParameters } from "../organisms/DataTable";
import { useState } from "react";
import {
  FirewallRecord,
  PaginatedListFirewallRecord,
} from "../../services/swagger";
import { FirewallService } from "../../services/api";
import CreateRecordDialog from "../organisms/CreateRecordDialog";
import useRefresh from "../../hooks/useRefresh";
import useConfirm from "../../hooks/useConfirm";

export default function SecurityPage() {
  const refresh = useRefresh();
  const [createDialog, setCreateDialog] = useState(false);
  const [records, setRecords] = useState<PaginatedListFirewallRecord>({
    count: 0,
    docs: [],
  });

  const loadRecords = async (query: QueryParameters) => {
    const recordsRes = await FirewallService.getRecordList({
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (recordsRes?.data?.data) setRecords(recordsRes?.data?.data);
  };

  const handleDelete = async (record: FirewallRecord) => {
    await FirewallService.deleteRecord({ id: record._id?.toString() || "" });
    refresh();
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadRecords(query);
  };

  const { confirm: confirmDelete, ConfirmDialog } = useConfirm();

  return (
    <ManagementTemplate paper>
      <ConfirmDialog />
      <CreateRecordDialog
        open={createDialog}
        onClose={() => setCreateDialog(false)}
      />
      <DataTable
        name="Firewall Records"
        headers={[
          { field: "date", name: "Date", type: "date" },
          { field: "ip", name: "IP", type: "string" },
          { field: "subnet", name: "Subnet", type: "string" },
          { field: "type", name: "Type", type: "string" },
          {
            field: "delete",
            name: "",
            type: "other",
            renderHeaderCell: () => (
              <TableCell align="center" width={70}>
                Action
              </TableCell>
            ),
            renderRowCell: (row: FirewallRecord) => (
              <TableCell>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => confirmDelete(() => handleDelete(row))}
                >
                  Delete
                </Button>
              </TableCell>
            ),
          },
        ]}
        onChange={handleDataTableChange}
        content={records}
        pagination
        queryFunction={FirewallService.getRecordListQuery}
        button={{
          text: "New Record",
          onClick: () => setCreateDialog(true),
        }}
      />
    </ManagementTemplate>
  );
}
