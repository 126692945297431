import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";
import useConfirm, { UseConfirmOptions } from "../../hooks/useConfirm";

export interface FormProps {
  title: string;
  children?: ReactNode;
  disableHeader?: boolean;
  disableHeaderCloseButton?: boolean;
  disableFooter?: boolean;
  disableCancelButton?: boolean;
  disableSubmitButton?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  disableEnter?: boolean;
  noForm?: boolean;
  confirm?: boolean;
  confirmOptions?: UseConfirmOptions;
  onSubmit?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function FormTemplate(props: FormProps) {
  const { confirm, ConfirmDialog } = useConfirm(props.confirmOptions);
  return (
    <Box
      component="form"
      sx={{
        height: 1,
        display: "flex",
        flexDirection: "column",
        borderRadius: "inherit",
        minWidth: { xs: 300, md: 400 },
      }}
      bgcolor="background.default"
      onSubmit={(e) => {
        e.preventDefault();
        if (props.noForm) e.stopPropagation();
        if (props.disableEnter) return;
        if (props.confirm) confirm(() => props.onSubmit?.());
        else props.onSubmit?.();
      }}
    >
      <ConfirmDialog />
      {!props.disableHeader && (
        <Box
          p={2}
          boxSizing="border-box"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={600} color="primary">
            {props.title}
          </Typography>
          {!props.disableHeaderCloseButton && (
            <IconButton onClick={props.onCancel}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Divider />
      <Box p={2} boxSizing="border-box" flexGrow={1} overflow="auto">
        {props.children}
      </Box>
      <Divider />
      {!props.disableFooter && (
        <Box
          display="flex"
          justifyContent="flex-end"
          bgcolor="background.default"
          p={2}
        >
          <Stack direction="row" spacing={2}>
            {!props.disableCancelButton && (
              <Button variant="outlined" color="error" onClick={props.onCancel}>
                {props.cancelButtonText ?? "Cancel"}
              </Button>
            )}
            {!props.disableSubmitButton && (
              <Button
                variant="contained"
                type={!props.disableEnter ? "submit" : undefined}
                onClick={() => {
                  if (props.disableEnter) props.onSubmit?.();
                }}
              >
                {props.submitButtonText ?? "Submit"}
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
