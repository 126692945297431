import { useMemo, useState } from "react";
import MainTemplate from "../templates/MainTemplate";
import { PaginatedListTicket, Ticket } from "../../services/swagger";
import { TicketService } from "../../services/api";
import DataTable, { QueryParameters } from "../organisms/DataTable";
import { Box, Button, Container, Paper, TableCell } from "@mui/material";
import PriorityChip from "../atoms/PriorityChip";
import { useNavigate } from "react-router";
import TicketStatus from "../atoms/TicketStatus";
import { useGlobalContext } from "../../contexts/Global";

export default function MyTicketsPage() {
  const { context } = useGlobalContext();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<PaginatedListTicket>({
    count: 0,
    docs: [],
  });

  const getTicketReadStatus = useMemo(() => {
    if (!context.user) return (ticket: Ticket) => ticket;
    return (ticket: Ticket): Ticket & { unRead?: boolean } => {
      let userUpdate = ticket.usersUpdated.find((userUpdate) => {
        if (typeof (userUpdate as any).username === "string")
          return (userUpdate as any).username === context.user?.username;
        else
          return (userUpdate.user as unknown as string) === context.user?._id;
      });
      const read = (userUpdate?.lastUpdate || 0) > ticket.lastUpdate;
      if (userUpdate) {
        return userUpdate.lastUpdate >= ticket.lastUpdate
          ? { ...ticket, unRead: !read }
          : { ...ticket, unRead: !read };
      } else return { ...ticket, unRead: !read };
    };
  }, [context.user]);

  const loadMyTickets = async (query: QueryParameters) => {
    const ticketsRes = await TicketService.getMyTicketList({
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortDirection: query.sortDirection,
      sortField: query.sortField,
    });
    if (ticketsRes?.data?.data) setTickets(ticketsRes?.data?.data);
  };

  const handleDataTableChange = (query: QueryParameters) => {
    loadMyTickets(query);
  };

  return (
    <MainTemplate>
      <Box
        bgcolor="background.paper"
        height={1}
        pt={4}
        pb={4}
        boxSizing="border-box"
      >
        <Container>
          <Paper
            sx={{
              bgcolor: (theme) => theme.palette.background.default,
              width: 1,
            }}
          >
            <DataTable
              name="My Tickets"
              button={{
                text: "New Ticket",
                onClick: () => navigate("/my-tickets/create"),
              }}
              pagination
              content={{
                count: tickets.count,
                docs: context.user
                  ? tickets.docs.map((ticket) => getTicketReadStatus(ticket))
                  : [],
              }}
              headers={[
                {
                  field: "id",
                  name: "ID",
                  type: "number",
                },
                {
                  field: "date",
                  name: "Date",
                  type: "date",
                },
                {
                  field: "title",
                  name: "Title",
                  type: "string",
                },
                {
                  field: "status",
                  name: "Status",
                  type: "other",
                  renderRowCell: (row: Ticket) => {
                    return (
                      <TableCell>
                        <TicketStatus status={row.status} />
                      </TableCell>
                    );
                  },
                },
                {
                  field: "location",
                  name: "Location",
                  type: "other",
                  renderRowCell: (row: Ticket) => (
                    <TableCell>{row.location || row.city || ""}</TableCell>
                  ),
                },
                {
                  field: "priority",
                  name: "Priority",
                  type: "other",
                  renderHeaderCell: () => (
                    <TableCell width={50} align="center">
                      Priority
                    </TableCell>
                  ),
                  renderRowCell: (row: Ticket) => {
                    return (
                      <TableCell align="center">
                        <PriorityChip priority={row.priority} />
                      </TableCell>
                    );
                  },
                },
                {
                  field: "delete",
                  name: "",
                  type: "other",
                  renderHeaderCell: () => (
                    <TableCell align="center" width={70}>
                      Action
                    </TableCell>
                  ),
                  renderRowCell: (row: Ticket) => (
                    <TableCell>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => navigate(`/my-tickets/${row._id}`)}
                      >
                        View
                      </Button>
                    </TableCell>
                  ),
                },
              ]}
              queryFunction={TicketService.getMyTicketListQuery}
              onChange={handleDataTableChange}
            />
          </Paper>
        </Container>
      </Box>
    </MainTemplate>
  );
}
