import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Filter,
  FilterValueType,
  OutputDTOQueryFilterTemplateArray,
  PaginatedListAny,
  QueryFilterTemplate,
  SortDirection,
} from "../../services/swagger";
import { useEffect, useRef, useState } from "react";
import { AxiosPromise } from "axios";
import FilterBox from "./FilterBox";
import { prepareFilterString } from "../../helpers/filters";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import useRefresh from "../../hooks/useRefresh";
import SortingArrow from "../atoms/SortingArrow";
import Add from "@mui/icons-material/Add";

export interface DataTableHeader {
  name: string;
  field: string;
  type: FilterValueType | "other";
  renderHeaderCell?: () => JSX.Element;
  renderRowCell?: (row: any) => JSX.Element;
}

export interface QueryParameters {
  filters: string;
  skip: number;
  limit: number;
  sortField?: string;
  sortDirection?: SortDirection;
}

interface DataTableProps {
  name: string;
  button?: Omit<ButtonProps, "sx"> & { text: string };
  headers: DataTableHeader[];
  content: PaginatedListAny;
  pagination?: boolean;
  queryFunction?: () => AxiosPromise<OutputDTOQueryFilterTemplateArray>;
  onClick?: (object: any) => void;
  onChange?: (query: QueryParameters) => void;
}

export default function DataTable(props: DataTableProps) {
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.None
  );
  const [filterBox, setFilterBox] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterTemplate, setFilterTemplate] = useState<QueryFilterTemplate[]>(
    []
  );
  const [filters, setFilters] = useState<Filter[]>([]);
  const isLoadable = useRef<boolean>(!Boolean(props.queryFunction));

  const loadQuery = async () => {
    const res = await props.queryFunction?.();
    if (res?.data?.data) {
      setFilterTemplate(res.data.data);
      for (const template of res.data.data) {
        if (template.defaultSortDirection) {
          setSortField(template.field);
          setSortDirection(template.defaultSortDirection);
          break;
        }
      }
      isLoadable.current = true;
    }
  };

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const handleClickSort = (field: string) => {
    if (field !== sortField) {
      setSortDirection(SortDirection.Desc);
      setSortField(field);
      return;
    }
    if (sortDirection === SortDirection.None)
      setSortDirection(SortDirection.Desc);
    if (sortDirection === SortDirection.Desc)
      setSortDirection(SortDirection.Asc);
    if (sortDirection === SortDirection.Asc)
      setSortDirection(SortDirection.None);
  };

  useEffect(() => {
    loadQuery();
  }, []);

  const handleChange = () => {
    if (!isLoadable.current) return;
    props.onChange?.({
      filters: prepareFilterString(filters),
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      sortDirection,
      sortField,
    });
  };

  useRefresh(handleChange);
  useEffect(handleChange, [
    filters,
    page,
    rowsPerPage,
    sortDirection,
    sortField,
  ]);

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box
        sx={{
          p: 2,
          bgcolor: (theme) => theme.palette.primary.light,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
        }}
      >
        <Typography
          fontWeight="bold"
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {props.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {Boolean(props.queryFunction) && (
            <IconButton onClick={() => setFilterBox((e) => !e)}>
              {filterBox ? (
                <FilterAltOffIcon
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                />
              ) : (
                <FilterAltIcon
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                />
              )}
            </IconButton>
          )}
          {props.button && (
            <Button
              //Next line of code to be revised
              startIcon={<Add />}
              sx={{
                ml: 2,
                bgcolor: (theme) => theme.palette.background.default,
                color: (theme) => theme.palette.text.primary,
                ":hover": {
                  bgcolor: (theme) => theme.palette.background.paper,
                },
              }}
              variant="contained"
              {...props.button}
            >
              {props.button.text}
            </Button>
          )}
        </Box>
      </Box>
      {Boolean(props.queryFunction) && (
        <Box>
          <FilterBox
            open={filterBox}
            filters={filterTemplate}
            onChange={(newFilters) => setFilters(newFilters)}
          />
        </Box>
      )}
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow component={Paper} sx={{ height: 50 }}>
              {props.headers.map((header) => {
                const sortable = Boolean(
                  filterTemplate.find((filter) => filter.field === header.field)
                    ?.sortable
                );
                if (header.renderHeaderCell) return header.renderHeaderCell();
                else
                  return (
                    <TableCell
                      key={header.field}
                      align="left"
                      width={(header.type === "date" && 140) || undefined}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontSize="inherit">
                          {header.name}
                        </Typography>
                        {sortable && (
                          <SortingArrow
                            onClick={() => handleClickSort(header.field)}
                            activeField={sortField}
                            field={header.field}
                            sort={sortDirection}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.content.docs.map((item, index) => {
              console.log(item.unRead);
              return (
                <TableRow
                  component={Paper}
                  key={item._id || index}
                  hover
                  sx={{
                    cursor: props.onClick ? "cursor" : undefined,
                    height: 60,
                    td: {
                      fontWeight: item.unRead ? "bold" : undefined,
                    },
                  }}
                >
                  {props.headers.map((header) => {
                    if (header.renderRowCell) return header.renderRowCell(item);
                    return (
                      <TableCell
                        key={header.field}
                        sx={{ textOverflow: "ellipsis" }}
                      >
                        {(header.type === FilterValueType.Date &&
                          new Date(item[header.field]).toLocaleString("tr")) ||
                          (header.type === FilterValueType.Boolean &&
                            item[header.field].toString()) ||
                          item[header.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {props.pagination && (
            <TableFooter
              component={Paper}
              sx={{ bgcolor: "background.default" }}
            >
              <TablePagination
                count={props.content.count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={[25, 50, 200]}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
