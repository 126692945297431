interface TabPanelProps {
  children?: any;
  index: any;
  value: any;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  if (value === index) return children;
  else return null;
}
